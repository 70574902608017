// api.js
import API_BASE_URL from '../config'; // Import the config

export async function authenticatedFetch(url, options = {}) {
  const token = localStorage.getItem('token');

  if (!token) {
    throw new Error('You are not authenticated. Please login again.');
  }

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  };

  const response = await fetch(`${API_BASE_URL}${url}`, {
    ...options,
    headers: {
      ...options.headers,
      ...headers
    }
  });

  const contentType = response.headers.get('content-type');
  let data;
  if (contentType && contentType.includes('application/json')) {
    data = await response.json(); // Parse the response body here
  } else {
    const text = await response.text();
    throw new Error(`Unexpected response format: ${text}`);
  }

  if (!response.ok) {
    throw new Error(data.error || 'An unexpected error occurred.');
  }

  return data;  // Return the parsed data
}
