import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import { fetchTeamById } from '../api/apiTeam';
import { fetchGoalsByTeamId } from '../api/apiGoal';
import '../index.css'; // Import the global stylesheet

const TeamPage = () => {
  const { teamId } = useParams(); // Get teamId from URL
  const [openModalGoal, setOpenModalGoal] = useState(null);
  const [rewardInput, setRewardInput] = useState('');  // New state for reward input
  const [teamName, setTeamName] = useState('');
  const [teamGoals, setTeamGoals] = useState([]);
  const [individualGoals, setIndividualGoals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTimeframe, setSelectedTimeframe] = useState('Day'); // Using React's useState to manage the selected tab
  const [memberCount, setMemberCount] = useState(0); // New state for member count

  const fetchTeamData = async () => {
    try {
      const teamResponse = await fetchTeamById(teamId);
      setTeamName(teamResponse.name);
      setMemberCount(teamResponse.members.length);

      const goalsResponse = await fetchGoalsByTeamId(teamId);
      const teamGoals = goalsResponse.filter(goal => goal.type === 'Team').map(goal => ({ ...goal, timeframe: goal.recurringFrequency || 'Day' }));
      const individualGoals = goalsResponse.filter(goal => goal.type === 'Individual').map(goal => ({ ...goal, timeframe: goal.recurringFrequency || 'Day' }));

      setTeamGoals(teamGoals);
      setIndividualGoals(individualGoals);
    } catch (error) {
      setError('Error fetching team data.');
      console.error('Error fetching team data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (teamId) {
      fetchTeamData();
    }
  }, [teamId]);

  const toggleModal = (index, goal) => {
    if (openModalGoal !== null) {
      setOpenModalGoal(null);
      setRewardInput('');
    } else {
      setOpenModalGoal(index);
      setRewardInput(goal.reward || '');
    }
  };

  const saveReward = () => {
    if (openModalGoal) {
      openModalGoal.reward = rewardInput;  // Update the reward in the goal
      toggleModal(null);  // Close the modal
    }
  };

  if (loading) {
    return (
      <div className="feed-container">
        <AppHeader />
        <div className="loading-container">
          <p>Loading team data...</p>
        </div>
        <AppFooter />
      </div>
    );
  }

  if (error) {
    return (
      <div className="feed-container">
        <AppHeader />
        <div className="loading-container">
          <p>{error}</p>
        </div>
        <AppFooter />
      </div>
    );
  }

  return (
    <div className="feed-container">
      <AppHeader>
      <div className="feed-wrapper">
        <div className="feed-container-desktop">
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Header as='h1' className="feed-summary">{teamName}</Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" verticalAlign="middle">
                <Label style={{ marginRight: '1em' }}>
                  <Icon name='users' /> {memberCount}
                </Label>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <div style={{ textAlign: 'center', width: '100%' }}>
            <Menu pointing secondary style={{ width: '100%' }}>
              {['Day', 'Week', 'Month', 'Quarter'].map(timeframe => (
                <Menu.Item
                  key={timeframe}
                  name={timeframe}
                  active={selectedTimeframe === timeframe}
                  onClick={() => setSelectedTimeframe(timeframe)}
                  style={{ color: 'white', flex: 1, textAlign: 'center' }}
                />
              ))}
            </Menu>
          </div>
          <div>
            <Grid>
              <Grid.Row className="feed-summary" style={{ marginTop: '1em', marginBottom: '.5em' }}>
                <Grid.Column width={10}>
                  <Header as='h2' className="feed-summary">Team Goals</Header>
                </Grid.Column>
                <Grid.Column width={6} textAlign="right" verticalAlign="middle">
                  <Label style={{ marginRight: '1em', marginTop: '1em' }}>
                    <Icon name='plus' /> Add Goal
                  </Label>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {teamGoals
              .filter(goal => goal.timeframe === selectedTimeframe)
              .map((goal, index) => (
                <div key={index} style={{ marginBottom: '1em', width: '90%', display: 'flex', justifyContent: 'center' }}>
                  <Card color='blue' style={{ width: '100%', padding: '0 5%' }}>
                    <Card.Content>
                      <Card.Header>
                        {goal.title}
                        <div style={{ fontSize: '0.8em', marginTop: '5px' }}>{goal.targetCompletionDate}</div>
                      </Card.Header>
                      {goal.reward ? (
                        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                          <Label color="green">
                            <Icon name='gift' /> {goal.reward}
                          </Label>
                        </div>
                      ) : (
                        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                          <Label as='a' color="blue" onClick={() => toggleModal(index, goal)}>
                            <Icon name='plus' /> Add Reward
                          </Label>
                        </div>
                      )}
                      <Card.Description>
                        {goal.description.substring(0, 60) + '...'}
                      </Card.Description>
                      <Grid>
                        <Grid.Row style={{ marginTop: '1em' }}>
                          <Grid.Column width={8} verticalAlign="middle">
                            <Icon name="plus" onClick={() => toggleModal(index, goal)} />Details
                          </Grid.Column>
                          <Grid.Column width={8} textAlign="right">
                            <Dropdown
                              inline
                              options={[
                                { key: '1', text: 'In Progress', value: 'In Progress' },
                                { key: '2', text: 'Completed', value: 'Completed' }
                              ]}
                              value={goal.status}
                              onChange={(e, data) => {
                                // handle the dropdown change here
                              }}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Card.Content>
                  </Card>
                  <Modal open={openModalGoal === index} onClose={() => toggleModal(null)}>
                    <Modal.Header>
                      {goal.title}
                      <Icon name='edit' style={{ float: 'right' }} />
                    </Modal.Header>
                    <Modal.Content>
                      <p>{goal.description}</p>
                      {/* Input to add or update reward */}
                      <div style={{ marginTop: '20px' }}>
                        <Label>Reward:</Label>
                        <Input
                          value={rewardInput}
                          onChange={e => setRewardInput(e.target.value)}
                          placeholder="Specify a reward..."
                        />
                      </div>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button onClick={saveReward}>Save Reward</Button>
                      <Button onClick={() => toggleModal(null)}>Close</Button>
                    </Modal.Actions>
                  </Modal>
                </div>
              ))}
          </div>

          <div>
            <Grid>
              <Grid.Row className="feed-summary" style={{ marginTop: '1em', marginBottom: '.5em' }}>
                <Grid.Column width={10}>
                  <Header as='h2' className="feed-summary">Individual Goals</Header>
                </Grid.Column>
                <Grid.Column width={6} textAlign="right" verticalAlign="middle">
                  <Label style={{ marginRight: '1em', marginTop: '1em' }}>
                    <Icon name='plus' /> Add Goal
                  </Label>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10em' }}>
            {individualGoals
              .filter(goal => goal.timeframe === selectedTimeframe)
              .map((goal, index) => (
                <div key={index} style={{ marginBottom: '1em', width: '90%', display: 'flex', justifyContent: 'center' }}>
                  <Card color='blue' style={{ width: '100%', padding: '0 5%' }}>
                    <Card.Content>
                      <Card.Header>
                        {goal.title}
                        <div style={{ fontSize: '0.8em', marginTop: '5px' }}>{goal.targetCompletionDate}</div>
                      </Card.Header>
                      {goal.reward ? (
                        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                          <Label color="green">
                            <Icon name='gift' /> {goal.reward}
                          </Label>
                        </div>
                      ) : (
                        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                          <Label as='a' color="blue" onClick={() => toggleModal(index, goal)}>
                            <Icon name='plus' /> Add Reward
                          </Label>
                        </div>
                      )}
                      <Card.Description>
                        {goal.description.substring(0, 60) + '...'}
                      </Card.Description>
                      <Grid>
                        <Grid.Row style={{ marginTop: '1em' }}>
                          <Grid.Column width={8} verticalAlign="middle">
                            <Icon name="plus" onClick={() => toggleModal(index, goal)} />Details
                          </Grid.Column>
                          <Grid.Column width={8} textAlign="right">
                            <Dropdown
                              inline
                              options={[
                                { key: '1', text: 'In Progress', value: 'In Progress' },
                                { key: '2', text: 'Completed', value: 'Completed' }
                              ]}
                              value={goal.status}
                              onChange={(e, data) => {
                                // handle the dropdown change here
                              }}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Card.Content>
                  </Card>
                  <Modal open={openModalGoal === index} onClose={() => toggleModal(null)}>
                    <Modal.Header>
                      {goal.title}
                      <Icon name='edit' style={{ float: 'right' }} />
                    </Modal.Header>
                    <Modal.Content>
                      <p>{goal.description}</p>
                      {/* Input to add or update reward */}
                      <div style={{ marginTop: '20px' }}>
                        <Label>Reward:</Label>
                        <Input
                          value={rewardInput}
                          onChange={e => setRewardInput(e.target.value)}
                          placeholder="Specify a reward..."
                        />
                      </div>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button onClick={saveReward}>Save Reward</Button>
                      <Button onClick={() => toggleModal(null)}>Close</Button>
                    </Modal.Actions>
                  </Modal>
                </div>
              ))}
          </div>
        </div>
      </div>
      </AppHeader>
      <AppFooter />
    </div>
  );
};

export default TeamPage;
