import React from 'react';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonButton,
  IonIcon,
  IonNote,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { heartOutline, chatbubbleOutline, cashOutline, target, addCircleOutline } from 'ionicons/icons';
import CommentSection from './CommentSection';
import RewardSection from './RewardSection';

const FeedEvent = ({
  item,
  index,
  array,
  handleToggleComments,
  handleToggleRewards,
  handleStatusChange,
  activeCommentId,
  activeRewardId,
  setActiveCommentId,
  setActiveRewardId,
  commentText,
  setCommentText,
  rewardAmount,
  setRewardAmount,
  handleAddComment,
  handleAddReward,
  formatDate,
  formatTimeRemaining,
  isTeamLeader,
}) => {
  const isLastItem = index === array.length - 1;
  const feedItemClass = isLastItem ? 'last-feed-item' : 'feed-item';
  const userName = item.userId
    ? `${item.userId.firstName} ${item.userId.lastName}`
    : 'Unknown user';
  const goalTitle = item.goalId ? item.goalId.title : 'Unknown Goal';
  const targetDate = item.goalId ? item.goalId.targetCompletionDate : null;
  const commentCount = item.comments ? item.comments.length : 0;
  const rewardSum = item.rewards
    ? item.rewards.reduce((sum, reward) => sum + reward.amount, 0)
    : 0;

  const goalTypeText =
    item.goalId.type === 'Team' ? 'created a new team goal:' : 'created a new goal:';

  return (
    <IonCard key={item._id} >
      <IonCardHeader>
        <IonCardTitle>
          {userName} {goalTypeText} {goalTitle}
        </IonCardTitle>
        {targetDate && (
          <IonCardSubtitle>{formatTimeRemaining(targetDate)}</IonCardSubtitle>
        )}
        <IonNote>Posted {formatDate(item.createdAt)}</IonNote>
      </IonCardHeader>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol style={{textAlign:'center'}}>
              <IonButton fill="clear" onClick={() => {/* Handle like functionality */}}>
                <IonIcon icon={heartOutline} />
                ({item.likes.length})
              </IonButton>
            </IonCol>
            <IonCol style={{textAlign:'center'}}>
              <IonButton
                fill="clear"
                onClick={() => handleToggleComments(item.goalId._id)}
              >
                <IonIcon icon={chatbubbleOutline} />
                ({commentCount})
              </IonButton>
            </IonCol>
            <IonCol style={{textAlign:'center'}}>
              <IonButton
                fill="clear"
                onClick={() => handleToggleRewards(item.goalId._id)}
              >
                <IonIcon icon={addCircleOutline} />
                ${rewardSum}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        {activeCommentId === item.goalId._id && (
          <CommentSection
            goalId={item.goalId._id}
            comments={item.comments}
            commentText={commentText}
            setCommentText={setCommentText}
            handleAddComment={handleAddComment}
            activeCommentId={activeCommentId}
            setActiveCommentId={setActiveCommentId}
            textColor="comment-text-white"
          />
        )}
        {activeRewardId === item.goalId._id && (
          <RewardSection
            goalId={item.goalId._id}
            rewards={item.rewards}
            rewardAmount={rewardAmount}
            setRewardAmount={setRewardAmount}
            handleAddReward={handleAddReward}
            textColor="comment-text-white"
          />
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default FeedEvent;
