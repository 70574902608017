import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonInput,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonAlert,
  IonText,
} from '@ionic/react';
import { useLocation, useHistory } from "react-router-dom";
import PendingMembers from './PendingMembers';
import { sendInvite, fetchPendingMembers } from '../api/apiInvite';

export default function InviteUsers({ mobile }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [inviteError, setInviteError] = useState(null);
  const [inviteCount, setInviteCount] = useState(0);

  const [pendingMembers, setPendingMembers] = useState([]);

  const location = useLocation();
  const history = useHistory();
  const orgID = location.state?.orgID || localStorage.getItem('activeOrganization');

  const rolesOptions = [
    { key: 'standard', value: 'Standard', text: 'Standard' },
    { key: 'admin', value: 'Admin', text: 'Admin' }
  ];

  useEffect(() => {
    const getPendingMembers = async () => {
      try {
        const data = await fetchPendingMembers(orgID);
        setPendingMembers(data || []);
      } catch (error) {
        console.error("There was an error fetching the pending members:", error);
      }
    };

    getPendingMembers();
  }, [orgID, inviteCount]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = {
      name,
      email,
      role,
      invitedBy: orgID,
      organizationId: orgID
    };
  
    try {
      const data = await sendInvite(formData);
      console.log("Invitation sent!", data.message);
      setInviteError(null);
      setName('');
      setEmail('');
      setRole('');
      setInviteCount(inviteCount + 1);
  
      // Re-fetch the pending members list
      const updatedMembers = await fetchPendingMembers(orgID);
      setPendingMembers(updatedMembers || []);
    } catch (error) {
      console.error('There was an error!', error.message || error);
      setInviteError(error.message || 'An unexpected error occurred.');
    }
  };

  const handleNext = () => {
    history.push('/create-teams', { state: { orgID } });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Invite Users</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        fullscreen
        style={{
          '--background': 'linear-gradient(-45deg, #006d77, #83c5be)',
        }}
      >
        <div
          style={{
            width: "100%",
            minHeight: "100vh",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: mobile ? '1em' : '2em',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            boxSizing: 'border-box',
          }}
        >
          {/* Centralized Container */}
          <div
            style={{
              maxWidth: '500px',
              width: '100%',
              borderRadius: '8px',
              marginBottom: '2em',
            }}
          >
            {/* Invite Form */}
            <IonList style={{ borderRadius: '10px', overflow: 'hidden' }}>
              <form onSubmit={handleSubmit}>
                <IonItem>
                  <IonInput
                    value={name}
                    onIonChange={(e) => setName(e.detail.value)}
                    labelPlacement="floating"
                    label="Name"
                    required
                  />
                </IonItem>
                <IonItem>
                  <IonInput
                    value={email}
                    onIonChange={(e) => setEmail(e.detail.value)}
                    labelPlacement="floating"
                    label="Email"
                    type="email"
                    required
                  />
                </IonItem>
                <IonItem>
                  <IonSelect
                    value={role}
                    labelPlacement="floating"
                    label="Role"
                    onIonChange={(e) => setRole(e.detail.value)}
                    required
                  >
                    {rolesOptions.map((option) => (
                      <IonSelectOption key={option.key} value={option.value}>
                        {option.text}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>

                {/* Error Message */}
                {inviteError && (
                  <IonText color="danger" style={{ textAlign: 'center', marginTop: '0.5em' }}>
                    {inviteError}
                  </IonText>
                )}

                {/* Buttons */}
                <div style={{ marginTop: '1.5em', gap: '0.5em' }}>
                  <IonButton expand="block" type="submit" color="primary">
                    Invite
                  </IonButton>
                  <IonButton expand="block" fill="outline" color="secondary" onClick={handleNext}>
                    Next
                  </IonButton>
                </div>
              </form>
            </IonList>
          </div>

          {/* Pending Members Section */}
          <div
            style={{
              maxWidth: '500px',
              width: '100%',
              borderRadius: '8px',
            }}
          >
            <PendingMembers
              orgID={orgID}
              mobile={mobile}
              inviteCount={inviteCount}
              pendingMembers={pendingMembers}
              setPendingMembers={setPendingMembers}
            />
          </div>
        </div>

        {/* Alert for Invite Errors */}
        <IonAlert
          isOpen={!!inviteError}
          onDidDismiss={() => setInviteError(null)}
          header={'Error'}
          message={inviteError}
          buttons={['OK']}
        />
      </IonContent>
    </IonPage>
  );
}
