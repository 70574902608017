import React from 'react';
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton } from '@ionic/react';
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>Cochago</IonTitle>
        <IonButtons slot="end">
          <IonButton routerLink="/">Home</IonButton>
          <IonButton routerLink="/profile">Profile</IonButton>
          <IonButton routerLink="/feed">Feed</IonButton>
          <IonButton routerLink="/login">Login</IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
