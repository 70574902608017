import { authenticatedFetch } from './api';

export const fetchTeams = async () => {
  try {
    const orgId = localStorage.getItem('activeOrganization');
    if (!orgId) {
      throw new Error('No active organization found.');
    }
    const response = await authenticatedFetch(`/api/teams/org/${orgId}`);
    return response;
  } catch (error) {
    throw new Error('There was an error fetching the teams: ' + error.message);
  }
};

export const fetchUserTeams = async (userId, activeOrgId) => {
    try {
      const response = await authenticatedFetch(`/api/teams/user/${userId}/org/${activeOrgId}`);
      const teamsData = response.map(team => ({ key: team._id, text: team.name, value: team._id }));
      return teamsData;
    } catch (error) {
      throw new Error('There was an error fetching the teams: ' + error.message);
    }
  };

  export const createTeam = async (teamData) => {
    try {
      const response = await authenticatedFetch(`/api/teams`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(teamData)
      });
      return response;
    } catch (error) {
      throw new Error('There was an error creating the team: ' + error.message);
    }
  };

  export const fetchTeamById = async (teamId) => {
    try {
      const response = await authenticatedFetch(`/api/teams/${teamId}`);
      return response;
    } catch (error) {
      throw new Error('There was an error fetching the team: ' + error.message);
    }
  };

  export const fetchOrganizationDetailsForTeamsList = async (orgId) => {
    try {
      const response = await authenticatedFetch(`/api/organization/${orgId}/details`);
      const userList = response.map(user => ({
        _id: user._id,
        name: user.name,
        type: user.type
      }));
      return userList;
    } catch (error) {
      throw new Error('There was an error fetching organization details: ' + error.message);
    }
  };
  
  export const fetchTeamsForTeamsList = async () => {
    try {
      const response = await authenticatedFetch(`/api/teams`);
      return response.teams;
    } catch (error) {
      throw new Error('There was an error fetching teams: ' + error.message);
    }
  };
  
  export const deleteTeamForTeamsList = async (teamId) => {
    try {
      const response = await authenticatedFetch(`/api/teams/${teamId}`, {
        method: 'DELETE'
      });
      return response;
    } catch (error) {
      throw new Error('There was an error deleting the team: ' + error.message);
    }
  };
  
  export const addSingleMemberForTeamsList = async (teamId, userToAdd) => {
    try {
      await authenticatedFetch(`/api/teams/${teamId}/users`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ usersToAdd: [{ id: userToAdd._id, type: userToAdd.type }] })
      });
    } catch (error) {
      throw new Error('There was an error adding the member: ' + error.message);
    }
  };
  
  export const removeMemberForTeamsList = async (teamId, memberId) => {
    try {
      const response = await authenticatedFetch(`/api/teams/${teamId}/users/${memberId}`, {
        method: 'DELETE'
      });
      return response;
    } catch (error) {
      throw new Error('There was an error removing the member: ' + error.message);
    }
  };