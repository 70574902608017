import React, { useState, useEffect } from 'react';
import { createStripeAccountLink, checkPaymentSetup } from '../api/apiStripe';
import { addReward } from '../api/apiGoal';

const StripeTest = () => {
  const [setup, setSetup] = useState(false);
  const [goalId, setGoalId] = useState('');
  const [rewardAmount, setRewardAmount] = useState('');

  useEffect(() => {
    const fetchSetupStatus = async () => {
      try {
        const isSetup = await checkPaymentSetup();
        setSetup(isSetup);
      } catch (error) {
        console.error('Error checking payment setup:', error);
      }
    };

    fetchSetupStatus();
  }, []);

  const handleSetup = async () => {
    try {
      const url = await createStripeAccountLink();
      window.location.href = url; // Redirect to Stripe onboarding
    } catch (error) {
      console.error('Error setting up Stripe:', error);
    }
  };

  const handleAddReward = async () => {
    try {
      await addReward(goalId, rewardAmount);
      alert('Reward added successfully');
    } catch (error) {
      console.error('Error adding reward:', error);
    }
  };

  return (
    <div>
      <h1>Stripe Integration Test</h1>
      <div>
        <h2>Stripe Account Setup</h2>
        {setup ? (
          <p>Payment setup is complete.</p>
        ) : (
          <div>
            <p>Payment setup is not complete. Please set up your Stripe account.</p>
            <button onClick={handleSetup}>Set Up Stripe Account</button>
          </div>
        )}
      </div>
      <div>
        <h2>Add Reward</h2>
        <input
          type="text"
          value={goalId}
          onChange={(e) => setGoalId(e.target.value)}
          placeholder="Goal ID"
        />
        <input
          type="number"
          value={rewardAmount}
          onChange={(e) => setRewardAmount(e.target.value)}
          placeholder="Reward Amount"
        />
        <button onClick={handleAddReward}>Add Reward</button>
      </div>
    </div>
  );
};

export default StripeTest;
