import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSpinner,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonIcon,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonMenu,
  IonList,
  IonItem,
  IonLabel,
  IonAvatar,
  IonBadge,
  IonMenuToggle,
  IonButtons,
  IonSplitPane,
  IonText,
  IonMenuButton,
} from '@ionic/react';
import {
  menu as menuIcon,
  arrowBackOutline,
  peopleOutline,
  addCircleOutline,
  notificationsOutline,
  chatbubbleOutline, // Updated icon
  personCircleOutline,
  heartOutline,
} from 'ionicons/icons';
import moment from 'moment-timezone';

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import CommentSection from './CommentSection';
import RewardSection from './RewardSection';
import AlertSidebar from './AlertSidebar';
import { fetchGoalDetails, addComment, addReward, changeStatus } from '../api/apiGoal';
import { fetchUserTeams } from '../api/apiTeam';
// import './GoalDetailPage.css'; // Optional: for additional custom styling

const GoalDetailPage = () => {
  const { goalId } = useParams();
  const [goal, setGoal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [commentText, setCommentText] = useState('');
  const [rewardAmount, setRewardAmount] = useState('');
  const [activeCommentId, setActiveCommentId] = useState(null);
  const [activeRewardId, setActiveRewardId] = useState(null);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [teams, setTeams] = useState([]);
  const history = useHistory();
  const orgId = localStorage.getItem('activeOrganization');

  const formatDate = (dateString) => {
    return moment(dateString).fromNow();
  };

  const formatTimeRemaining = (dateString) => {
    const now = moment();
    const targetDate = moment.tz(dateString, 'America/Los_Angeles').endOf('day');
    const duration = moment.duration(targetDate.diff(now));
    const days = duration.asDays();
    const hours = duration.asHours();

    if (days < -1) {
      return `${Math.abs(Math.ceil(days))} days ago`;
    } else if (days < 0) {
      return `${Math.abs(Math.ceil(hours))} hours ago`;
    } else if (days >= 1) {
      return `in ${Math.floor(days)} days`;
    } else {
      return `in ${Math.floor(hours)} hours`;
    }
  };

  const handleAddComment = async () => {
    try {
      const newComment = await addComment(goalId, commentText);
      setGoal((prevGoal) => ({
        ...prevGoal,
        comments: [...prevGoal.comments, newComment],
      }));
      setCommentText('');
      setActiveCommentId(null);
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleAddReward = async () => {
    try {
      const newReward = await addReward(goalId, rewardAmount);
      setGoal((prevGoal) => ({
        ...prevGoal,
        rewards: [...prevGoal.rewards, newReward],
      }));
      setRewardAmount('');
      setActiveRewardId(null);
    } catch (error) {
      console.error('Error adding reward:', error);
    }
  };

  const handleToggleComments = (goalId) => {
    setActiveCommentId(activeCommentId === goalId ? null : goalId);
    if (activeRewardId === goalId) setActiveRewardId(null);
  };

  const handleToggleRewards = (goalId) => {
    setActiveRewardId(activeRewardId === goalId ? null : goalId);
    if (activeCommentId === goalId) setActiveCommentId(null);
  };

  const handleStatusChange = async (newStatus) => {
    try {
      await changeStatus(goalId, newStatus);
      setGoal((prevGoal) => ({
        ...prevGoal,
        status: newStatus === 'Done' ? 'Pending Approval' : newStatus,
      }));
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const isTeamLeader = (associatedTeam) => {
    if (!associatedTeam || !associatedTeam.leader) {
      return false;
    }
    return associatedTeam.leader.some(
      (leader) =>
        String(leader.memberId) === String(localStorage.getItem('userId'))
    );
  };

  const handleToggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const handleSidebarHide = () => {
    setSidebarVisible(false);
  };

  useEffect(() => {
    const fetchGoalData = async () => {
      try {
        const data = await fetchGoalDetails(goalId);
        setGoal(data);
      } catch (error) {
        console.error('Error fetching goal:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchTeamsData = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const activeOrgId = localStorage.getItem('activeOrganization');
        const teamsData = await fetchUserTeams(userId, activeOrgId);
        setTeams(teamsData);
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    };

    fetchGoalData();
    fetchTeamsData();
  }, [goalId]);

  if (loading) {
    return (
      <IonPage>
        <AppHeader />
        <IonContent className="ion-padding ion-text-center">
          <IonSpinner name="crescent" />
          <p>Loading goal details...</p>
        </IonContent>
        <AppFooter />
      </IonPage>
    );
  }

  if (error) {
    return (
      <IonPage>
        <AppHeader />
        <IonContent className="ion-padding ion-text-center">
          <IonText color="danger">
            <h2>Error loading goal: {error}</h2>
          </IonText>
        </IonContent>
        <AppFooter />
      </IonPage>
    );
  }

  if (!goal) {
    return (
      <IonPage>
        <AppHeader />
        <IonContent className="ion-padding ion-text-center">
          <IonText color="medium">
            <h2>Goal not found.</h2>
          </IonText>
        </IonContent>
        <AppFooter />
      </IonPage>
    );
  }

  const userName = goal.user
    ? `${goal.user.firstName} ${goal.user.lastName}`
    : 'Unknown user';
  const goalTitle = goal.title;
  const targetDate = goal.targetCompletionDate;
  const commentCount = goal.comments ? goal.comments.length : 0;
  const rewardSum = goal.rewards
    ? goal.rewards.reduce((sum, reward) => sum + reward.amount, 0)
    : 0;

  const statusOptions = [
    { key: '1', text: 'To Do', value: 'To Do' },
    { key: '2', text: 'Doing', value: 'Doing' },
    { key: '3', text: 'Done', value: 'Done' },
    { key: '4', text: 'Archived', value: 'Archived' },
    {
      key: '5',
      text: 'Pending Approval',
      value: 'Pending Approval',
      disabled: true,
    },
    {
      key: '6',
      text: 'Complete',
      value: 'Complete',
      disabled: !isTeamLeader(goal.associatedTeam),
    },
  ];

  return (
    <IonPage>
      {/* Main Content */}
      <IonContent className="ion-padding">
        {/* Back to Feed Button */}
        <IonButton
          fill="clear"
          color="primary"
          onClick={() => history.push(`/feed/${orgId}`)}
          className="back-button"
        >
          <IonIcon icon={arrowBackOutline} slot="start" />
          Back to Feed
        </IonButton>

        {/* Goal Details Card */}
        <IonCard color="light">
          <IonCardHeader>
            <IonGrid fixed="true">
              <IonRow>
                <IonCol size="8" className="ion-text-left">
                  <IonText color="primary">
                    <strong>{userName}</strong>
                  </IonText>
                </IonCol>
                <IonCol size="4" className="ion-text-right">
                  <IonText color="medium">
                    {targetDate && formatTimeRemaining(targetDate)}
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>

          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonCol size="8">
                <IonCardTitle>{goalTitle}</IonCardTitle>  
                </IonCol>
                <IonCol size="4" className="ion-text-right">
                  <IonSelect
                    interface="popover"
                    placeholder="Select Status"
                    value={goal.status}
                    onIonChange={(e) => handleStatusChange(e.detail.value)}
                  >
                    {statusOptions.map((option) => (
                      <IonSelectOption
                        key={option.key}
                        value={option.value}
                        disabled={option.disabled}
                      >
                        {option.text}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonCol>
              </IonRow>
           
              <IonRow>
                <IonCol className="ion-text-left">
                  <IonText color="dark">
                    <p className="goal-description">
                      {goal.description
                        ? `${goal.description.substring(0, 60)}...`
                        : 'No description available'}
                    </p>
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
            {/* Interactions: Likes, Comments, Rewards */}
            <IonGrid fixed="true">
              <IonRow>
                <IonCol style={{ textAlign: 'center' }}>
                  <IonButton fill="clear" onClick={() => { /* Handle like functionality */ }}>
                    <IonIcon icon={heartOutline} />
                    ({goal.likes ? goal.likes.length : 0})
                  </IonButton>
                </IonCol>
                <IonCol style={{ textAlign: 'center' }}>
                  <IonButton
                    fill="clear"
                    onClick={() => handleToggleComments(goalId)}
                  >
                    <IonIcon icon={chatbubbleOutline} />
                    ({commentCount})
                  </IonButton>
                </IonCol>
                <IonCol style={{ textAlign: 'center' }}>
                  <IonButton
                    fill="clear"
                    onClick={() => handleToggleRewards(goalId)}
                  >
                    <IonIcon icon={addCircleOutline} />
                    ${rewardSum}
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>

          {/* Comments Section */}
          {activeCommentId === goalId && (
            <IonCardContent>
              <CommentSection
                goalId={goalId}
                comments={goal.comments}
                commentText={commentText}
                setCommentText={setCommentText}
                handleAddComment={handleAddComment}
                activeCommentId={activeCommentId}
                setActiveCommentId={setActiveCommentId}
                textColor="ion-text-dark" // Example usage
              />
            </IonCardContent>
          )}

          {/* Rewards Section */}
          {activeRewardId === goalId && (
            <IonCardContent>
              <RewardSection
                goalId={goalId}
                rewards={goal.rewards}
                rewardAmount={rewardAmount}
                setRewardAmount={setRewardAmount}
                handleAddReward={handleAddReward}
                textColor="ion-text-dark" // Example usage
              />
            </IonCardContent>
          )}
        </IonCard>
      </IonContent>
      <AppFooter />

      {/* Alert Sidebar */}
      <AlertSidebar
        visible={sidebarVisible}
        onHide={() => setSidebarVisible(false)}
      />
    </IonPage>
  );
};

export default GoalDetailPage;
