import { authenticatedFetch } from './api';

export const fetchFeed = async (organizationName) => {
  try {
    const data = await authenticatedFetch(`/api/feed/${organizationName}`);
    return data.slice(0, 50).map(item => ({
      ...item,
      comments: item.goalId.comments || [],
      rewards: item.goalId.rewards || [],
      status: item.goalId.status || 'To Do'
    }));
  } catch (error) {
    throw new Error('Error fetching feed: ' + error.message);
  }
};

export const fetchUserTeams = async (userId, activeOrgId) => {
  try {
    const response = await authenticatedFetch(`/api/teams/user/${userId}/org/${activeOrgId}`);
    const teamsData = response.map(team => ({ key: team._id, text: team.name, value: team._id }));
    return teamsData;
  } catch (error) {
    throw new Error('Error fetching teams: ' + error.message);
  }
};

export const addComment = async (goalId, commentText) => {
  try {
    const newComment = await authenticatedFetch(`/api/goals/${goalId}/comments`, {
      method: 'POST',
      body: JSON.stringify({ text: commentText }),
    });
    return newComment;
  } catch (error) {
    throw new Error('Error adding comment: ' + error.message);
  }
};

export const addReward = async (goalId, rewardAmount) => {
  try {
    const newReward = await authenticatedFetch(`/api/goals/${goalId}/rewards`, {
      method: 'POST',
      body: JSON.stringify({ amount: rewardAmount }),
    });
    return newReward;
  } catch (error) {
    throw new Error('Error adding reward: ' + error.message);
  }
};

export const changeStatus = async (goalId, newStatus) => {
  try {
    let endpoint = `/api/goals/${goalId}/status`;
    let body = { status: newStatus };
    let method = 'PATCH';

    if (newStatus === 'Done') {
      endpoint = `/api/goals/${goalId}/pending`;
      body = {};
      method = 'POST';
    }

    if (newStatus === 'Complete') {
      endpoint = `/api/goals/${goalId}/complete`;
      body = {};
      method = 'POST';
    }

    const response = await authenticatedFetch(endpoint, {
      method,
      body: JSON.stringify(body),
    });

    return response;
  } catch (error) {
    throw new Error('Error updating status: ' + error.message);
  }
};
