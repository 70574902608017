import React, { createContext, useState, useContext, useEffect } from 'react';
import { fetchAlerts, markAlertAsRead } from '../api/apiAlert';

const AlertContext = createContext();

export const useAlert = () => {
  return useContext(AlertContext);
};

export const AlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAndSetAlerts = async () => {
      try {
        const alertData = await fetchAlerts();
        setAlerts(alertData);
      } catch (error) {
        console.error('There was an error fetching the alerts', error.message || error);
      } finally {
        setLoading(false);
      }
    };

    fetchAndSetAlerts();
  }, []);

  const handleMarkAsRead = async (alert, conditionCallback) => {
    try {
      if (conditionCallback && !conditionCallback(alert)) {
        return;
      }
      await markAlertAsRead(alert._id);
      setAlerts((prevAlerts) =>
        prevAlerts.map((a) =>
          a._id === alert._id ? { ...a, read: true } : a
        )
      );
    } catch (error) {
      console.error('There was an error marking the alert as read', error.message || error);
    }
  };

  const markAllAlertsAsRead = async () => {
    try {
      await Promise.all(
        alerts.map(async (alert) => {
          if (!alert.read && alert.type === 'New Reward') {
            await markAlertAsRead(alert._id);
          }
        })
      );
      setAlerts((prevAlerts) =>
        prevAlerts.map((alert) =>
          alert.type === 'New Reward' ? { ...alert, read: true } : alert
        )
      );
    } catch (error) {
      console.error('Error marking all alerts as read:', error);
    }
  };

  return (
    <AlertContext.Provider value={{ alerts, loading, handleMarkAsRead, markAllAlertsAsRead }}>
      {children}
    </AlertContext.Provider>
  );
};
