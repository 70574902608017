import React from 'react';
import CommentSection from './CommentSection';
import RewardSection from './RewardSection';
import { useHistory } from 'react-router-dom';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonText,
} from '@ionic/react';
import {
  heartOutline,
  chatbubbleOutline,
  cashOutline,
} from 'ionicons/icons';

const TableView = ({
  item,
  index,
  array,
  handleToggleComments,
  handleToggleRewards,
  handleStatusChange,
  activeCommentId,
  activeRewardId,
  setActiveCommentId,
  setActiveRewardId,
  commentText,
  setCommentText,
  rewardAmount,
  setRewardAmount,
  handleAddComment,
  handleAddReward,
  formatTimeRemaining,
  isTeamLeader,
  formatDate,
}) => {
  const history = useHistory();
  const isLastItem = index === array.length - 1;
  const userName = item.userId ? `${item.userId.firstName} ${item.userId.lastName}` : 'Unknown user';
  const goalTitle = item.goalId ? item.goalId.title : 'Unknown Goal';
  const targetDate = item.goalId ? item.goalId.targetCompletionDate : null;
  const commentCount = item.comments ? item.comments.length : 0;
  const rewardSum = item.rewards ? item.rewards.reduce((sum, reward) => sum + reward.amount, 0) : 0;

  const goalTypeText = item.goalId.type === 'Team' ? 'created a new team goal:' : 'created a new goal:';

  const statusOptions = [
    { key: '1', text: 'To Do', value: 'To Do' },
    { key: '2', text: 'Doing', value: 'Doing' },
    { key: '3', text: 'Done', value: 'Done' },
    { key: '4', text: 'Archived', value: 'Archived' },
    { key: '5', text: 'Submitted', value: 'Pending Approval', disabled: true },
    {
      key: '6',
      text: 'Complete',
      value: 'Complete',
      disabled: !isTeamLeader(item.goalId.associatedTeam),
    },
  ];

  const getStatusValue = (status) => {
    return status === 'Pending Approval' ? 'Submitted' : status;
  };

  const getDropdownValue = (status) => {
    return status === 'Pending Approval' ? 'Pending Approval' : status;
  };

  const handleCardClick = () => {
    history.push(`/goal/${item.goalId._id}`);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <IonCard
      key={item._id}
      style={{
        padding: '1em',
        marginTop: '1em',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
      onClick={handleCardClick}
    >
      <IonCardHeader>
        <IonGrid>
          <IonRow>
            <IonCol size="9">
              <IonCardTitle>{goalTitle}</IonCardTitle>
            </IonCol>
            <IonCol size="3" className="ion-text-right">
              <IonSelect
                interface="popover"
                value={getDropdownValue(item.status)}
                onIonChange={(e) => handleStatusChange(item.goalId._id, e.detail.value)}
                onClick={stopPropagation}
                style={{ fontWeight: 'bold', color: '#4183c4' }}
              >
                {statusOptions.map((option) => (
                  <IonSelectOption key={option.key} value={option.value} disabled={option.disabled}>
                    {option.text}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardHeader>
      <IonCardContent>
        <IonText>
          {userName}
          <br />
          {targetDate && formatTimeRemaining(targetDate)}
        </IonText>
        <IonGrid>
          <IonRow style={{ marginTop: '1em' }}>
            <IonCol
              className="icon-black"
              onClick={stopPropagation}
              style={{ textAlign: 'left' }}
            >
              <IonIcon icon={heartOutline} /> ({item.likes.length})
            </IonCol>
            <IonCol
              className="icon-black"
              onClick={(e) => {
                stopPropagation(e);
                handleToggleComments(item.goalId._id);
              }}
              style={{ textAlign: 'center' }}
            >
              <IonIcon icon={chatbubbleOutline} /> ({commentCount})
            </IonCol>
            <IonCol
              className="icon-black"
              onClick={(e) => {
                stopPropagation(e);
                handleToggleRewards(item.goalId._id);
              }}
              style={{ textAlign: 'right' }}
            >
              <IonIcon icon={cashOutline} /> {rewardSum}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
      {activeCommentId === item.goalId._id && (
        <IonCardContent style={{ marginLeft: '30px' }} onClick={stopPropagation}>
          <CommentSection
            goalId={item.goalId._id}
            comments={item.comments}
            commentText={commentText}
            setCommentText={setCommentText}
            handleAddComment={handleAddComment}
            activeCommentId={activeCommentId}
            setActiveCommentId={setActiveCommentId}
            textColor="comment-text-black"
          />
        </IonCardContent>
      )}
      {activeRewardId === item.goalId._id && (
        <IonCardContent style={{ marginLeft: '30px' }} onClick={stopPropagation}>
          <RewardSection
            goalId={item.goalId._id}
            rewards={item.rewards}
            rewardAmount={rewardAmount}
            setRewardAmount={setRewardAmount}
            handleAddReward={handleAddReward}
            textColor="comment-text-black"
          />
        </IonCardContent>
      )}
    </IonCard>
  );
};

export default TableView;
