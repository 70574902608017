import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  IonPage,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonButton,
  IonText,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';

const Register = ({ mobile }) => {
  const [firstName, setFirstName] = useState(''); // Changed
  const [lastName, setLastName] = useState('');   // Added
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [inviteValid, setInviteValid] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const inviteKey = new URLSearchParams(location.search).get('inviteKey');

  useEffect(() => {
    const validateInvite = async () => {
      try {
        console.log('Validating invite key on frontend:', inviteKey);
        const response = await fetch(`/api/invite/${inviteKey}`);
        if (!response.ok) {
          const errorData = await response.json();
          console.log('Invite validation error:', errorData.error);
          setError('Invalid or expired invite key.');
        } else {
          console.log('Invite key validated successfully on frontend:', inviteKey);
          setInviteValid(true);
        }
      } catch (err) {
        console.error('Error validating invite key on frontend:', err);
        setError('An unexpected error occurred.');
      }
    };

    if (inviteKey) {
      validateInvite();
    } else {
      setError('No invite key provided.');
    }
  }, [inviteKey]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      console.log('Registering user with invite key on frontend:', inviteKey);
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          inviteKey,
          firstName, // Updated
          lastName,  // Added
          email,
          password
        })
      });

      if (response.ok) {
        const data = await response.json();
        console.log('User registered successfully on frontend:', data);
        localStorage.setItem('token', data.token);
        localStorage.setItem('activeOrganization', data.activeOrganization);
        localStorage.setItem('userId', data.userId);
        history.push(`/feed/${data.activeOrganization}`);
      } else {
        const errorData = await response.json();
        console.log('Registration error on frontend:', errorData.error);
        setError(errorData.error || 'An unexpected error occurred.');
      }
    } catch (err) {
      console.error('Error registering user on frontend:', err);
      setError('An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <IonPage>
      <IonContent
        fullscreen
        style={{
          '--background': 'linear-gradient(-45deg, #006d77, #83c5be)',
        }}
      >
        <IonGrid
          style={{
            height: mobile ? '100vh' : 'auto',
            paddingBottom: mobile ? '0' : '5em',
          }}
        >
          <IonRow className="ion-justify-content-center ion-align-items-center" style={{ height: '100%' }}>
            <IonCol size="12" sizeMd="8" sizeLg="6">
              <h1
                style={{
                  fontSize: '4em',
                  fontWeight: 'normal',
                  marginBottom: 0,
                  textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                Cochago
              </h1>
              <h1
                style={{
                  textAlign: 'center',
                  color: 'white',
                  fontSize: '3em',
                  fontWeight: 'normal',
                  marginBottom: '1em',
                  textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
                }}
              >
                Register
              </h1>
              {error && (
                <IonText color="danger">
                  <p style={{ textAlign: 'center' }}>{error}</p>
                </IonText>
              )}
              {inviteValid && (
                <form onSubmit={handleSubmit}>
                  <IonItem>
                    <IonInput
                      label="First Name"
                      labelPlacement="floating"
                      value={firstName}
                      onIonChange={(e) => setFirstName(e.detail.value)}
                      required
                    />
                  </IonItem>
                  <IonItem>
                    <IonInput
                      label="Last Name"
                      labelPlacement="floating"
                      value={lastName}
                      onIonChange={(e) => setLastName(e.detail.value)}
                      required
                    />
                  </IonItem>
                  <IonItem>
                    <IonInput
                      label="Email"
                      labelPlacement="floating"
                      type="email"
                      value={email}
                      onIonChange={(e) => setEmail(e.detail.value)}
                      required
                    />
                  </IonItem>
                  <IonItem>
                    <IonInput
                      label="Password"
                      labelPlacement="floating"
                      type="password"
                      value={password}
                      onIonChange={(e) => setPassword(e.detail.value)}
                      required
                    />
                  </IonItem>
                  <IonItem>
                    <IonInput
                      label="Confirm Password"
                      labelPlacement="floating"
                      type="password"
                      value={confirmPassword}
                      onIonChange={(e) => setConfirmPassword(e.detail.value)}
                      required
                    />
                  </IonItem>
                  <IonButton
                    type="submit"
                    expand="block"
                    disabled={loading}
                    style={{ marginTop: '1em' }}
                  >
                    Register
                  </IonButton>
                </form>
              )}
              <IonLoading isOpen={loading} message={'Registering...'} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Register;
