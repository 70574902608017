import React from "react";
import  Header from './Header';

export default function ProfilePage() {
  return (
      <div style={{width:"100%",margin:"auto"}}>
      <Header />
      <h1>Profile Page!</h1>
    </div>
  );
}
