import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom'; // Use useHistory for react-router-dom v5
import { useAlert } from '../context/AlertContext';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonButton,
} from '@ionic/react';
import { close, ellipseOutline, ellipse } from 'ionicons/icons';

const AlertSidebar = ({ visible, onHide }) => {
  const { alerts, handleMarkAsRead } = useAlert();
  const prevVisibleRef = useRef(visible);
  const history = useHistory();

  useEffect(() => {
    if (!visible && prevVisibleRef.current) {
      // Modal has just been closed, mark all "New Reward" alerts as read
      alerts.forEach((alert) => {
        if (alert.type === 'New Reward' && !alert.read) {
          handleMarkAsRead(alert);
        }
      });
    }
    prevVisibleRef.current = visible;
  }, [visible, alerts, handleMarkAsRead]);

  const handleAlertClick = (alert) => {
    handleMarkAsRead(alert, (alert) => ['Goal Rejected', 'Goal Approved'].includes(alert.type));
    history.push(`/goal/${alert.goalId}`);
    onHide();
  };

  // Sort alerts so unread alerts are at the top
  const sortedAlerts = alerts.slice().sort((a, b) => a.read - b.read);

  return (
    <IonModal isOpen={visible} onDidDismiss={onHide}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Alerts</IonTitle>
          <IonButton slot="end" fill="clear" onClick={onHide}>
            <IonIcon icon={close} />
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {sortedAlerts.map((alert) => (
            <IonItem button key={alert._id} onClick={() => handleAlertClick(alert)}>
              <IonIcon
                slot="start"
                icon={alert.read ? ellipseOutline : ellipse}
                color={alert.read ? 'medium' : 'danger'}
              />
              <IonLabel>{alert.message}</IonLabel>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default AlertSidebar;
