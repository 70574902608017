import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonInput,
  IonLabel,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonAlert
} from '@ionic/react';
import { useHistory } from "react-router-dom";
import TeamsList from './TeamsList';
import { authenticatedFetch } from '../api/api.js';

export default function CreateTeams({ mobile }) {
  const [teamName, setTeamName] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [teamError, setTeamError] = useState(null);
  const [parentTeams, setParentTeams] = useState([]);
  const [selectedParentTeam, setSelectedParentTeam] = useState('');
  const orgId = localStorage.getItem('activeOrganization');
  const [selectedLeader, setSelectedLeader] = useState(null);
  const history = useHistory();
  const [teamsUpdatedAt, setTeamsUpdatedAt] = useState(Date.now()); // State to track team updates

  useEffect(() => {
    const fetchOrganizationDetails = async () => {
      try {
        const data = await authenticatedFetch(`/api/organization/${orgId}/details`);
        const userList = data.map(user => ({
          _id: user._id,
          name: user.name,
          type: user.type
        }));
        setUsersList(userList);
      } catch (error) {
        console.error('There was an error fetching organization details!', error.message || error);
      }
    };
    fetchOrganizationDetails();
  }, [orgId]);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const teamData = await authenticatedFetch(`/api/teams/org/${orgId}`);
        setParentTeams(teamData);
      } catch (error) {
        console.error('There was an error fetching the teams', error.message || error);
      }
    };
    fetchTeams();
  }, [orgId, teamsUpdatedAt]); // Depend on teamsUpdatedAt

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!teamName || !selectedLeader) {
      setTeamError("Please provide a team name and choose a leader.");
      return;
    }

    let actualUsers = selectedUsers.filter(userId => {
      const user = usersList.find(u => u._id === userId);
      return user && user.type === "User";
    });
    let actualPendingMembers = selectedUsers.filter(userId => {
      const user = usersList.find(u => u._id === userId);
      return user && user.type === "Invite";
    });

    if (selectedLeader.type === "User" && !actualUsers.includes(selectedLeader.id)) {
      actualUsers.push(selectedLeader.id);
    } else if (selectedLeader.type === "Invite" && !actualPendingMembers.includes(selectedLeader.id)) {
      actualPendingMembers.push(selectedLeader.id);
    }

    const formData = {
      name: teamName,
      users: actualUsers,
      parentTeam: selectedParentTeam,
      pendingMembers: actualPendingMembers,
      leader: [{
        memberId: selectedLeader.id,
        memberType: selectedLeader.type
      }],
      orgId: orgId
    };

    try {
      const data = await authenticatedFetch(`/api/teams`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      console.log("Team created!", data.message);
      setTeamError(null);
      setTeamName('');
      setSelectedUsers([]);
      setSelectedLeader(null);
      setSelectedParentTeam('');
      setTeamsUpdatedAt(Date.now()); // Update the timestamp
    } catch (error) {
      console.error('There was an error!', error.message || error);
      setTeamError(error.message || 'An unexpected error occurred.');
    }
  };

  const handleNext = () => {
    history.push(`/feed/${orgId}`);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Create Teams</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        fullscreen
        style={{
          '--background': 'linear-gradient(-45deg, #006d77, #83c5be)',
        }}
      >
        <div
            style={{
              width: "100%",
              minHeight: "100vh",
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: mobile ? '1em' : '2em',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              boxSizing: 'border-box',
            }}
          >
            <div
              style={{
                maxWidth: '500px',
                width: '100%',
                borderRadius: '8px',
                marginBottom: '2em',
              }}
            >
              <IonList style={{ borderRadius: '10px', overflow: 'hidden' }}>
                <IonItem>
                  <IonInput
                    value={teamName}
                    onIonChange={(e) => setTeamName(e.detail.value)}
                    labelPlacement="floating"
                    label="Team Name"
                  />
                </IonItem>
                {parentTeams.length > 0 && (
                  <IonItem>
                    <IonSelect
                      value={selectedParentTeam}
                      labelPlacement="floating"
                      label="Parent Team (Optional)"
                      placeholder="Select Parent Team"
                      onIonChange={(e) => setSelectedParentTeam(e.detail.value)}
                    >
                      {parentTeams.map(team => (
                        <IonSelectOption key={team._id} value={team._id}>{team.name}</IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                )}
                <IonItem>
                  <IonSelect
                    value={selectedLeader?.id}
                    labelPlacement="floating"
                    label="Team Leader"
                    placeholder="Select Leader"
                    onIonChange={(e) => {
                      const leaderData = usersList.find(user => user._id === e.detail.value);
                      setSelectedLeader({
                        id: leaderData._id,
                        type: leaderData.type
                      });
                    }}
                  >
                    {usersList.map(user => (
                      <IonSelectOption key={user._id} value={user._id}>{user.name}</IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <IonSelect
                    multiple
                    value={selectedUsers}
                    labelPlacement="floating"
                    label="Team Members"
                    placeholder="Select Users"
                    onIonChange={(e) => setSelectedUsers(e.detail.value)}
                  >
                    {usersList.filter(user => user._id !== selectedLeader?.id).map(user => (
                      <IonSelectOption key={user._id} value={user._id}>{user.name}</IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>

              <div style={{ marginTop: '1em', marginBottom: '1em' }}>
                {teamError && (
                  <IonAlert
                    isOpen={!!teamError}
                    onDidDismiss={() => setTeamError(null)}
                    message={teamError}
                    buttons={['OK']}
                  />
                )}
                <IonButton expand="block" onClick={handleSubmit}>
                  Create Team
                </IonButton>
                <IonButton expand="block" fill="outline" color="secondary" onClick={handleNext}>
                  Next
                </IonButton>
              </div>
              </IonList>


              <TeamsList
                teamsUpdatedAt={teamsUpdatedAt}
                setTeamsUpdatedAt={setTeamsUpdatedAt}
                orgId={orgId}
              />
            </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
