// WaitlistPage.js
import React, { useState, useEffect } from 'react';
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonFooter,
  IonInput,
  IonList,
  IonItem,
  IonButton,
  IonAlert,
} from '@ionic/react';
import { mailOutline } from 'ionicons/icons';
import { useHistory, useLocation } from 'react-router-dom';
import '../HomePage.css'; // Ensure this file includes the necessary styling

const WaitlistPage = ({ mobile }) => {
    const [email, setEmail] = useState('');
    const [subscribeError, setSubscribeError] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertHeader, setAlertHeader] = useState("");
  
    const history = useHistory();
  
    const handleSubscribe = async (e) => {
      e.preventDefault();
      setShowLoading(true);
      setSubscribeError(null);
      setAlertMessage('');
      setAlertHeader('');
  
      // Frontend Email Validation
      const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (!email || !emailRegex.test(email)) {
        setAlertMessage('Please enter a valid email address.');
        setAlertHeader('Error');
        setShowAlert(true);
        setShowLoading(false);
        return;
      }
  
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/subscribers`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email })
        });
  
        const data = await response.json();
  
        if (response.ok && data.success) {
          setAlertMessage(data.message || 'Successfully subscribed to the waitlist.');
          setAlertHeader('Success');
          setShowAlert(true);
          setEmail(''); // Clear the input field
        } else {
          setAlertMessage(data.message || 'Subscription failed. Please try again.');
          setAlertHeader('Error');
          setShowAlert(true);
        }
      } catch (error) {
        console.error('Subscription Error:', error);
        setAlertMessage('An unexpected error occurred. Please try again later.');
        setAlertHeader('Error');
        setShowAlert(true);
      } finally {
        setShowLoading(false);
      }
    };

  return (
    <IonPage>

      {/* Main Content */}
      <IonContent fullscreen>
        {/* Hero Section */}
        <div className="hero-section">
          <IonGrid className="content-container">
            <IonRow className="ion-justify-content-center">
              <IonCol size="12" sizeMd="8" className="ion-text-center">
                <h1 className="hero-title">Cochago</h1>
                <h2 className="hero-subtitle">Do More Together</h2>
              </IonCol>
            </IonRow>

            {/* Email Subscription */}
            <IonRow className="ion-justify-content-center" style={{ marginTop: '2em' }}>
              <IonCol size="12" sizeMd="6" className="ion-text-center">
                    <IonInput
                      label="Enter Your Email"
                      labelPlacement="floating"
                      fill="outline"
                      placeholder="Enter Your Email"
                      type="email"
                      color="light"
                      className="wait-input"
                      value={email}
                      onIonChange={(e) => setEmail(e.detail.value)}
                      required
                    />
                <IonButton
                  expand="block"
                  onClick={handleSubscribe}
                  className="join-waitlist-button"
                  style={{ marginTop: '1em' }}
                >
                  Join Waitlist
                  <IonIcon icon={mailOutline} slot="end" />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        {/* Features Section */}
        <div className="features-section">
          <IonGrid className="content-container">
            <IonRow className="ion-justify-content-center">
                <IonCol size="12" className="ion-text-center">
                    <div className="section-divider">
                    <IonText>Peer to Peer Rewards to Drive Success</IonText>
                    </div>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol size="12" sizeMd="6" className="ion-text-center">
                <IonCard className="feature-card">
                  <IonCardHeader>
                    <IonCardTitle>Unify Your Team's Vision</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <p>
                      Cochago empowers your organization with a structured approach to set, track, and
                      reward goals. Our platform brings teams together, helping them understand the
                      bigger picture and work towards shared objectives seamlessly.
                    </p>
                  </IonCardContent>
                </IonCard>
              </IonCol>

              <IonCol size="12" sizeMd="6" className="ion-text-center">
                <IonCard className="feature-card">
                  <IonCardHeader>
                    <IonCardTitle>Transform Achievements into Success</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <p>
                      Every accomplishment, big or small, contributes to the larger goals of the
                      organization. Celebrate individual milestones and see how they ladder up to
                      drive overall success.
                    </p>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol size="12" sizeMd="6" className="ion-text-center">
                <IonButton
                  expand="block"
                  href="https://blog.cochago.com/about"                  className="join-waitlist-button"
                  style={{ marginTop: '1em' }}
                >
                  About
                </IonButton>
                </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        {/* Supporting Research Section */}
        <div className="research-section">
          <IonGrid className="content-container">
            <IonRow className="ion-justify-content-center">
              <IonCol size="12" className="ion-text-center">
                <div className="section-divider">
                  <IonText>Supporting Research</IonText>
                </div>
              </IonCol>
            </IonRow>

            <IonRow className="ion-justify-content-center">
              <IonCol size="12" sizeMd="4">
                <IonCard className="research-card">
                  <IonCardHeader>
                    <IonCardTitle>Employee Recognition and Performance: The Role of Pride (2022)</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <p>
                      This study reveals that recognizing employees' achievements fosters both pride
                      and increased task performance. Meaningful recognition not only motivates
                      individuals to achieve their goals but also creates a strong organizational
                      culture by encouraging greater involvement and cooperation.
                    </p>
                    <IonButton href="https://www.mdpi.com/2071-1050/14/3/1631" target="_blank" size="small">
                      Learn more
                    </IonButton>
                  </IonCardContent>
                </IonCard>
              </IonCol>

              <IonCol size="12" sizeMd="4">
                <IonCard className="research-card">
                  <IonCardHeader>
                    <IonCardTitle>Goal Setting and Organizational Success: Motivation through Rewards (2023)</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <p>
                      Research supports that specific, challenging goals paired with rewards lead to
                      improved productivity and performance. By creating a sense of achievement and
                      commitment to the organization's vision, these strategies foster a culture of
                      high performance and continuous engagement.
                    </p>
                    <IonButton href="https://www.researchgate.net/publication/363025052" target="_blank" size="small">
                      Learn more
                    </IonButton>
                  </IonCardContent>
                </IonCard>
              </IonCol>

              <IonCol size="12" sizeMd="4">
                <IonCard className="research-card">
                  <IonCardHeader>
                    <IonCardTitle>Boosting Organizational Performance through Motivation (2023)</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <p>
                      Intrinsic and extrinsic motivation play crucial roles in enhancing workplace
                      performance. This research highlights how meaningful work and external rewards—
                      like financial incentives—create a self-rewarding loop, boosting job
                      satisfaction, creativity, and overall success within teams.
                    </p>
                    <IonButton href="https://ijbeg.org/publication/v6i1a3/" target="_blank" size="small">
                      Learn more
                    </IonButton>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        {/* Footer */}
        <IonFooter>
          <div className="footer">
            <IonGrid className="content-container">
              <IonRow>
                <IonCol sizeMd="3" sizeXs="12" className="footer-section">
                  <h4>About</h4>
                  <IonList>
                    <IonItem button href="https://blog.cochago.com/about">
                      <IonText>About</IonText>
                    </IonItem>
                    <IonItem button onClick={() => history.push('/contact')}>
                      <IonText>Contact Us</IonText>
                    </IonItem>
                  </IonList>
                </IonCol>

                <IonCol sizeMd="7" sizeXs="12" className="footer-section">
                  <h4>Stay Connected with Cochago</h4>
                  <p>
                    Join our newsletter for the latest updates, features, and success stories.
                    Let's achieve more, together!
                  </p>
                  <div className="subscribe-form">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="9" sizeMd="10">
                          <IonInput
                            placeholder="Enter your email..."
                            type="email"
                            value={email}
                            onIonChange={(e) => setEmail(e.detail.value)}
                            className="rounded-input"
                          />
                        </IonCol>
                        <IonCol size="3" sizeMd="2">
                          <IonButton onClick={handleSubscribe} fill="solid" className="join-waitlist-button">
                            <IonIcon icon={mailOutline} />
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonFooter>

        {/* Alert for Subscription Messages */}
        <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            header={alertHeader}
            message={alertMessage}
            buttons={['OK']}
            />
      </IonContent>
    </IonPage>
  );
};

export default WaitlistPage;
