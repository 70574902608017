import React, { useEffect, useState } from 'react';
import {
  IonFooter,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonBadge,
} from '@ionic/react';
import { peopleOutline, addCircleOutline, notificationsOutline } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import AlertSidebar from './AlertSidebar';
import { fetchUnreadAlertsCount } from '../api/apiAlert';

const AppFooter = () => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchUnreadCount = async () => {
      try {
        const count = await fetchUnreadAlertsCount();
        setUnreadCount(count);
      } catch (error) {
        console.error('Error fetching unread alerts count:', error);
      }
    };

    fetchUnreadCount();
  }, []);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const handleCreateGoalClick = () => {
    history.push('/create-goal');
  };

  const handleViewTeamsClick = () => {
    history.push('/teams');
  };

  return (
    <>
      <IonFooter>
        <IonToolbar
          style={{
            '--background': 'linear-gradient(0deg, #006d77, #479d9d)',
          }}
        >
          <IonGrid>
            <IonRow className="ion-justify-content-around ion-align-items-center">
              {/* People Button */}
              <IonCol size="auto">
                <IonButton fill="clear" color="light" onClick={() => history.push('/people')}>
                  <IonIcon icon={peopleOutline} size="large" />
                </IonButton>
              </IonCol>

              {/* Add Goal Button */}
              <IonCol size="auto">
                <IonButton fill="outline" color="light" onClick={handleCreateGoalClick}>
                  <IonIcon icon={addCircleOutline} size="large" />
                </IonButton>
              </IonCol>

              {/* Notifications Button */}
              <IonCol size="auto" className="ion-position-relative">
                <IonButton fill="clear" color="light" onClick={toggleSidebar}>
                  <IonIcon icon={notificationsOutline} size="large" />
                  {unreadCount > 0 && (
                    <IonBadge color="danger" className="notification-badge"></IonBadge>
                  )}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonFooter>
      <AlertSidebar visible={sidebarVisible} onHide={() => setSidebarVisible(false)} />
    </>
  );
};

export default AppFooter;
