import { authenticatedFetch } from './api';


export const createStripeAccountLink = async () => {
  try {
    const data = await authenticatedFetch('/api/stripe/create-account-link', {
      method: 'POST',
    });
    return data.url;
  } catch (error) {
    throw new Error('Error creating Stripe account link: ' + error.message);
  }
};

export const checkPaymentSetup = async () => {
  try {
    const data = await authenticatedFetch('/api/stripe/check-payment-setup', {
      method: 'GET',
    });
    return data.setup;
  } catch (error) {
    throw new Error('Error checking payment setup: ' + error.message);
  }
};
