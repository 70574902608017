import React from "react";
import  Header from './Header';
import { useParams } from "react-router-dom";

export default function ProfileDetailPage() {
  const { username } = useParams();
  return (
    <div style={{width:"100%",margin:"auto"}}>
      <Header />
      <h1>Profile Page for {username}!</h1>
    </div>
  );
}
