import React, { useState } from 'react';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';

const feedStyles = {
  paddingTop: '.5em',
  marginBottom: '0',
  marginLeft: '.5em',
  color: '#f5f5f5',  // Main text color set to a light shade
  'a': {
    color: '#f5f5f5'
  }
};

const styles = {
  feedWrapper: {
    height: 'calc(100vh  - 72px)', // Adjust [YourHeaderHeight] based on your actual header's height
    overflowY: 'scroll',
    backgroundColor: '#479d9d',
    scrollbarWidth: 'none',  // For Firefox
    msOverflowStyle: 'none',  // For Internet Explorer and Edge
    '&::-webkit-scrollbar': {
      display: 'none'  // For Chrome, Safari and Opera
    },
  },
  feedContainerDesktop: {
    maxWidth: '800px', // or whatever width you prefer
    margin: '0 auto',  // auto margins on left and right will center it
  }
};



const TeamPage = (props) => {
  const [openModalGoal, setOpenModalGoal] = useState(null);
  const [rewardInput, setRewardInput] = useState('');  // New state for reward input
  const teamName = "Sample Team";
  const teamGoals = [
    {
      timeframe: "Day",
      goals: [
        {
          header: "Goal A",
          description: "This is a detailed description of Goal A.",
          status: "In Progress",
          date: "10-26-2023",
          reward: "Lunch Voucher"
        },
        {
          header: "Goal B",
          description: "This is a detailed description of Goal B.",
          status: "Completed",
          date: "10-26-2023"
        }
      ]
    },
    {
      timeframe: "Week",
      goals: [
        {
          header: "Goal c",
          description: "This is a detailed description of Goal A.",
          status: "In Progress",
          date: "10-26-2023",
          reward: "Lunch Voucher"
        },
        {
          header: "Goal d",
          description: "This is a detailed description of Goal B.",
          status: "Completed",
          date: "10-26-2023"
        }
      ]
    },
    {
      timeframe: "Month",
      goals: [
        {
          header: "Goal e",
          description: "This is a detailed description of Goal A.",
          status: "In Progress",
          date: "10-26-2023",
          reward: "Lunch Voucher"
        },
        {
          header: "Goal f",
          description: "This is a detailed description of Goal B.",
          status: "Completed",
          date: "10-26-2023"
        }
      ]
    },
    {
      timeframe: "Quarter",
      goals: [
        {
          header: "Goal g",
          description: "This is a detailed description of Goal A.",
          status: "In Progress",
          date: "10-26-2023",
          reward: "Lunch Voucher"
        },
        {
          header: "Goal h",
          description: "This is a detailed description of Goal B.",
          status: "Completed",
          date: "10-26-2023"
        }
      ]
    },
  ];

  const individualGoals = [
      {
        member: "Elliot",
        timeframe: "Day",
        goals: [
          {
            header: "Goal Ahhjkjh",
            description: "This is a detailed description of Goal A.",
            status: "In Progress",
            date: "10-26-2023",
            reward: "Lunch Voucher"
          },
          {
            header: "Goal Bawwrrr",
            description: "This is a detailed description of Goal B.",
            status: "Completed",
            date: "10-26-2023"
          }
        ]
      },
      {
        member: "Elliot",
        timeframe: "Week",
        goals: [
          {
            header: "Goal Asdafweqrsd",
            description: "This is a detailed description of Goal A.",
            status: "In Progress",
            date: "10-26-2023",
            reward: "Lunch Voucher"
          },
          {
            header: "Goal Bfaeeeve",
            description: "This is a detailed description of Goal B.",
            status: "Completed",
            date: "10-26-2023"
          }
        ]
      },
      {
        member: "Elliot",
        timeframe: "Month",
        goals: [
          {
            header: "Goal Aasdfffff",
            description: "This is a detailed description of Goal A.",
            status: "In Progress",
            date: "10-26-2023"
          },
          {
            header: "Goal Bdfswqre",
            description: "This is a detailed description of Goal B.",
            status: "Completed",
            date: "10-26-2023",
            reward: "Lunch Voucher"
          }
        ]
      },
      {
        member: "Elliot",
        timeframe: "Quarter",
        goals: [
          {
            header: "Goal asdf",
            description: "This is a detailed description of Goal A.",
            status: "In Progress",
            date: "10-26-2023"
          },
          {
            header: "Goal B3rgfd",
            description: "This is a detailed description of Goal B.",
            status: "Completed",
            date: "10-26-2023",
            reward: "Lunch Voucher"
          }
        ]
      },
      // ... other team members
  ];

  const toggleModal = (index, goal) => {
      if (openModalGoal !== null) {
          setOpenModalGoal(null);
          setRewardInput('');
      } else {
          setOpenModalGoal(index);
          setRewardInput(goal.reward || '');
      }
  };


  const saveReward = () => {
    if (openModalGoal) {
      openModalGoal.reward = rewardInput;  // Update the reward in the goal
      toggleModal(null);  // Close the modal
    }
  };

  const [selectedTimeframe, setSelectedTimeframe] = React.useState('Day'); // Using React's useState to manage the selected tab

  return (
    <AppHeader>
      <div style={styles.feedWrapper}>
        <div style={styles.feedContainerDesktop}>
          <Grid>
              <Grid.Row>
                  <Grid.Column width={12}>
                      <Header as='h1' style={feedStyles}>{teamName}</Header>
                  </Grid.Column>
                  <Grid.Column width={4} textAlign="right" verticalAlign="middle">
                    <Label style={{marginRight:'1em'}}>
                      <Icon name='users'/> 5
                    </Label>
                  </Grid.Column>
              </Grid.Row>
          </Grid>

          <div style={{ textAlign: 'center', width: '100%' }}>
              <Menu pointing secondary style={{ width: '100%' }}>
                  {teamGoals.map(goal => (
                      <Menu.Item
                          key={goal.timeframe}
                          name={goal.timeframe}
                          active={selectedTimeframe === goal.timeframe}
                          onClick={() => setSelectedTimeframe(goal.timeframe)}
                          style={{ color: 'white', flex: 1, textAlign: 'center'  }}
                      />
                  ))}
              </Menu>
          </div>
          <div>
            <Grid>
              <Grid.Row style={{...feedStyles, marginTop: '1em', marginBottom: '.5em'}}>
                <Grid.Column width={10}>
                  <Header as='h2' style={feedStyles}>Team Goals</Header>
                </Grid.Column>
                <Grid.Column width={6} textAlign="right" verticalAlign="middle">
                  <Label style={{marginRight:'1em', marginTop: '1em'}}>
                    <Icon name='plus' /> Add Goal
                  </Label>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {teamGoals
              .filter(goalSet => goalSet.timeframe === selectedTimeframe)
              .flatMap(goalSet => goalSet.goals)
              .map((goal, index) => (
                <div key={index} style={{ marginBottom: '1em', width: '90%', display: 'flex', justifyContent: 'center' }}>
                  <Card color='blue' style={{ width: '100%', padding: '0 5%' }}>
                    <Card.Content>
                      <Card.Header>
                          {goal.header}
                          <div style={{ fontSize: '0.8em', marginTop: '5px' }}>{goal.date}</div>
                      </Card.Header>
                      {goal.reward ? (
                          <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                              <Label color="green">
                                  <Icon name='gift' /> {goal.reward}
                              </Label>
                          </div>
                      ) : (
                          <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                              <Label as='a' color="blue" onClick={() => toggleModal(index, goal)
}>
                                  <Icon name='plus' /> Add Reward
                              </Label>
                          </div>
                      )}
                      <Card.Description>
                        {goal.description.substring(0, 60) + '...'}
                      </Card.Description>
                      <Grid>
                        <Grid.Row style={{ marginTop: '1em' }}>
                          <Grid.Column width={8} verticalAlign="middle">
                            <Icon name="plus" onClick={() => toggleModal(index, goal)
} />Details
                          </Grid.Column>
                          <Grid.Column width={8} textAlign="right">
                            <Dropdown
                              inline
                              options={[
                                { key: '1', text: 'In Progress', value: 'In Progress' },
                                { key: '2', text: 'Completed', value: 'Completed' }
                              ]}
                              value={goal.status}
                              onChange={(e, data) => {
                                // handle the dropdown change here
                              }}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Card.Content>
                  </Card>
                  <Modal open={openModalGoal === index} onClose={() => toggleModal(null)}>
                    <Modal.Header>
                      {goal.header}
                      <Icon name='edit' style={{ float: 'right' }} />
                    </Modal.Header>
                    <Modal.Content>
                      <p>{goal.description}</p>
                      {/* Input to add or update reward */}
                      <div style={{ marginTop: '20px' }}>
                        <Label>Reward:</Label>
                        <Input
                          value={rewardInput}
                          onChange={e => setRewardInput(e.target.value)}
                          placeholder="Specify a reward..."
                        />
                      </div>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button onClick={saveReward}>Save Reward</Button>
                      <Button onClick={() => toggleModal(null)}>Close</Button>
                    </Modal.Actions>
                  </Modal>
                </div>
            ))}
          </div>

          <div>
            <Grid>
              <Grid.Row style={{...feedStyles, marginTop: '1em', marginBottom: '.5em'}}>
                <Grid.Column width={10}>
                  <Header as='h2' style={feedStyles}>Individual Goals</Header>
                </Grid.Column>
                <Grid.Column width={6} textAlign="right" verticalAlign="middle">
                  <Label style={{marginRight:'1em', marginTop: '1em'}}>
                    <Icon name='plus' /> Add Goal
                  </Label>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10em' }}>
            {individualGoals
              .filter(member => member.timeframe === selectedTimeframe)
              .map((memberGoals, index) => (
                memberGoals.goals.map((goal, gIndex) => (
                  <div key={gIndex} style={{ marginBottom: '1em', width: '90%', display: 'flex', justifyContent: 'center' }}>
                    <Card color='blue' style={{ width: '100%', padding: '0 5%' }}>
                      <Card.Content>
                        <Card.Header>
                            {goal.header}
                            <div style={{ fontSize: '0.8em', marginTop: '5px' }}>{goal.date}</div>
                        </Card.Header>
                          {goal.reward ? (
                              <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                                  <Label color="green">
                                      <Icon name='gift' /> {goal.reward}
                                  </Label>
                              </div>
                          ) : (
                              <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                                  <Label as='a' color="blue" onClick={() => toggleModal(index, goal)
}>
                                      <Icon name='plus' /> Add Reward
                                  </Label>
                              </div>
                          )}
                        <Card.Description>
                          {goal.description.substring(0, 60) + '...'}
                        </Card.Description>
                        <Grid>
                          <Grid.Row style={{ marginTop: '1em' }}>
                            <Grid.Column width={8} verticalAlign="middle">
                              <Icon name="plus" onClick={() => toggleModal(index, goal)
} />Details
                            </Grid.Column>
                            <Grid.Column width={8} textAlign="right">
                              <Dropdown
                                inline
                                options={[
                                  { key: '1', text: 'In Progress', value: 'In Progress' },
                                  { key: '2', text: 'Completed', value: 'Completed' }
                                ]}
                                value={goal.status}
                                onChange={(e, data) => {
                                  // handle the dropdown change here
                                }}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Card.Content>
                    </Card>
                    <Modal open={openModalGoal === index} onClose={() => toggleModal(null)}>
                      <Modal.Header>
                        {goal.header}
                        <Icon name='edit' style={{ float: 'right' }} />
                      </Modal.Header>
                      <Modal.Content>
                        <p>{goal.description}</p>
                        {/* Input to add or update reward */}
                        <div style={{ marginTop: '20px' }}>
                          <Label>Reward:</Label>
                          <Input
                            value={rewardInput}
                            onChange={e => setRewardInput(e.target.value)}
                            placeholder="Specify a reward..."
                          />
                        </div>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button onClick={saveReward}>Save Reward</Button>
                        <Button onClick={() => toggleModal(null)}>Close</Button>
                      </Modal.Actions>
                    </Modal>
                  </div>
                ))
              ))}
          </div>
          ))
        ))}
      </div>
    </div>
    <AppFooter />
  </AppHeader>
  )
}

export default TeamPage;
