import React, { useState } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import {
  IonPage,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonButton,
  IonText,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';

const Login = ({ mobile }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const history = useHistory(); // Updated to useHistory for React Router v5
  const location = useLocation();
  const wasRedirected = location.state?.redirected;
  const redirectTo = location.state?.from || '/feed';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when the form is submitted

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });

      const data = await response.json();

      if (response.ok) {
        console.log("Login successful!", data.message);
        localStorage.setItem('token', data.token);  // Store the token
        history.push(redirectTo);  // Redirect using history.push()
      } else {
        console.error("Login failed:", data.error);
        setErrorMessage(data.error + " Consider resetting your password.");
      }
    } catch (error) {
      console.error('There was an error!', error);
      setErrorMessage('An unexpected error occurred.');
    } finally {
      setLoading(false); // Set loading to false after the operation completes
    }
  };

  return (
    <IonPage>
      <IonContent
        fullscreen
        style={{
          '--background': 'linear-gradient(-45deg, #006d77, #83c5be)',
        }}
      >
        <IonGrid
          style={{
            height: '100vh',
            paddingBottom: mobile ? '0' : '5em',
          }}
        >
          <IonRow className="ion-justify-content-center ion-align-items-center" style={{ height: '100%' }}>
            <IonCol size="12" sizeMd="8" sizeLg="6">
              <h1
                style={{
                  fontSize: '4em',
                  fontWeight: 'normal',
                  marginBottom: 0,
                  textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                Cochago
              </h1>
              <h1
                style={{
                  textAlign: 'center',
                  color: 'white',
                  fontSize: '3em',
                  fontWeight: 'normal',
                  marginBottom: '3em',
                  textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
                }}
              >
                Login
              </h1>
              {wasRedirected && (
                <IonText color="danger">
                  <p style={{ textAlign: 'center' }}>You must be logged in to access that page.</p>
                </IonText>
              )}
              <form onSubmit={handleSubmit}>
                <IonItem>
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput
                    type="email"
                    value={email}
                    onIonChange={(e) => setEmail(e.detail.value)}
                    required
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Password</IonLabel>
                  <IonInput
                    type="password"
                    value={password}
                    onIonChange={(e) => setPassword(e.detail.value)}
                    required
                  />
                </IonItem>
                {errorMessage && (
                  <IonText color="danger">
                    <p style={{ textAlign: 'center' }}>{errorMessage}</p>
                  </IonText>
                )}
                <IonButton
                  type="submit"
                  expand="block"
                  style={{
                    backgroundColor: '#7b2cbf',
                    color: 'white',
                    marginBottom: '1em',
                  }}
                >
                  Login
                </IonButton>
                <Link to="/signup">
                  <IonButton
                    expand="block"
                    fill="outline"
                    style={{
                      color: 'white',
                      border: '1px solid white',
                    }}
                  >
                    New here? Sign Up
                  </IonButton>
                </Link>
                <div style={{ textAlign: 'center', marginTop: '1em' }}>
                  <Link to="/reset-password" style={{ color: 'white' }}>
                    Reset Password
                  </Link>
                </div>
              </form>
              <IonLoading isOpen={loading} message={'Logging in...'} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;
