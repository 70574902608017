import React from 'react';
import ReactDOM from 'react-dom';  // Import from 'react-dom' instead of 'react-dom/client'
import { setupIonicReact } from '@ionic/react';  // Import setupIonicReact
import './index.css';

/* Add Ionic CSS imports */
import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional Ionic CSS Utilities */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';


import App from './App';
import reportWebVitals from './reportWebVitals';

setupIonicReact();  // Initialize Ionic React

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


// For performance measurements (optional)
reportWebVitals();
