import React from "react";
import {
  IonButton,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonLabel,
  IonText,
  IonAccordionGroup,
  IonAccordion
} from '@ionic/react';
import { deletePendingMember, updatePendingMemberRole } from '../api/apiInvite';

export default function PendingMembers({
  orgID,
  mobile,
  inviteCount,
  pendingMembers,
  setPendingMembers
}) {
  const rolesOptions = [
    { key: 'standard', value: 'Standard', text: 'Standard' },
    { key: 'admin', value: 'Admin', text: 'Admin' }
  ];

  const handleDeleteMember = async (memberId) => {
    try {
      const response = await deletePendingMember(memberId);
  
      console.log('Response:', response);
  
      // Check if the response indicates success
      if (response.message === "Invite deleted successfully") {
        console.log("Member deleted!");
        const updatedMembers = pendingMembers.filter(member => member._id !== memberId);
        setPendingMembers(updatedMembers);
      } else if (response.error) {
        console.error("Failed to delete member:", response.error);
      } else {
        console.error("Failed to delete member:", response.message || "Unknown error");
      }
    } catch (error) {
      console.error('There was an error deleting the member!', error.message || error);
    }
  };
  
  
  

  const handleEditRole = async (memberId, newRole) => {
    try {
      const response = await updatePendingMemberRole(memberId, newRole);
  
      // response is the parsed data
      console.log('Response:', response);
  
      // If the response contains an error, handle it
      if (response.error) {
        console.error("Failed to update member role:", response.error);
      } else {
        console.log("Member role updated!", response);
        const updatedMembers = pendingMembers.map(member => {
          if (member._id === memberId) {
            return response; // Use the updated member data
          }
          return member;
        });
        setPendingMembers(updatedMembers);
      }
    } catch (error) {
      console.error('There was an error updating the member role!', error.message || error);
    }
  };
  

  return (
    <div style={{
      width: '100%',
      padding: '0 1em',
      marginTop: '2em',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <IonText style={{
        textAlign: "center",
        color: 'white',
        fontSize: '2em',
        fontWeight: 'normal',
        marginBottom: '1em',
        textShadow: '2px 2px 4px rgba(0,0,0,0.1)'
      }}>Pending Members</IonText>
      <IonAccordionGroup style={{ width: '100%' }}>
        {pendingMembers.map(member => (
          <IonAccordion key={member._id} value={member._id} style={{ width: '100%' }}>
            <IonItem slot="header" style={{ width: '100%' }}>
              <IonLabel>
                <h2>{member.name}</h2>
                <p>{member.email}</p>
              </IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
              <IonItem>
                <IonLabel>Role</IonLabel>
                <IonSelect
                  value={member.role}
                  placeholder="Select Role"
                  onIonChange={(e) => handleEditRole(member._id, e.detail.value)}
                >
                  {rolesOptions.map(option => (
                    <IonSelectOption key={option.key} value={option.value}>{option.text}</IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
              <IonButton color="danger" expand="block" onClick={() => handleDeleteMember(member._id)}>
                Delete
              </IonButton>
            </div>
          </IonAccordion>
        ))}
      </IonAccordionGroup>
    </div>
  );
}
