import React, { useState } from 'react';
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonInput,
  IonButton,
  IonAlert,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
} from '@ionic/react';
import { useHistory, useLocation } from 'react-router-dom';

const SECRET_PASSWORD = 'TestTest#123'; // Secret password

const SecretAccess = () => {
  const history = useHistory();
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);

  const handleAccess = () => {
    if (password === SECRET_PASSWORD) {
      // Set a flag in localStorage to allow access
      localStorage.setItem('hasSecretAccess', 'true');

      // Redirect to the intended page or HomePage
      const params = new URLSearchParams(location.search);
      const redirectTo = params.get('redirect') || '/home';
      history.push(redirectTo);
    } else {
      setShowError(true);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Secret Access</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid className="ion-padding">
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" sizeMd="6" className="ion-text-center">
              <h2>Enter Secret Password</h2>
              <IonInput
                type="password"
                placeholder="Password"
                value={password}
                onIonChange={(e) => setPassword(e.detail.value)}
                style={{ marginTop: '1em' }}
              />
              <IonButton
                expand="block"
                onClick={handleAccess}
                style={{ marginTop: '1em' }}
              >
                Submit
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonAlert
          isOpen={showError}
          onDidDismiss={() => setShowError(false)}
          header={'Incorrect Password'}
          message={'The password you entered is incorrect. Please try again.'}
          buttons={['OK']}
        />
      </IonContent>
    </IonPage>
  );
};

export default SecretAccess;
