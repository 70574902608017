import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'; // Use useHistory instead of useNavigate
import { IonPage, IonContent, IonIcon } from '@ionic/react';
import { checkmarkCircleOutline } from 'ionicons/icons';
import '../index.css'; // Ensure your index.css file is imported

const Success = () => {
  const history = useHistory(); // Initialize useHistory

  useEffect(() => {
    const orgId = localStorage.getItem('activeOrganization'); // Fetch orgId from localStorage

    const timer = setTimeout(() => {
      history.push(`/feed/${orgId}`);
    }, 2000); // Redirect after 2 seconds

    return () => clearTimeout(timer);
  }, [history]);

  return (
    <IonPage>
      <IonContent
        fullscreen
        className="success-container"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <IonIcon
          icon={checkmarkCircleOutline}
          size="large"
          className="checkmark"
          style={{ fontSize: '5em', color: 'green' }}
        />
      </IonContent>
    </IonPage>
  );
};

export default Success;
