import React from 'react';
import {
  IonList,
  IonItem,
  IonLabel,
  IonTextarea,
  IonButton,
  IonIcon,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonAvatar,
} from '@ionic/react';
import { chatbubbleEllipsesOutline, personCircleOutline } from 'ionicons/icons';
import moment from 'moment-timezone';

const CommentSection = ({
  goalId,
  comments,
  commentText,
  setCommentText,
  handleAddComment,
  activeCommentId,
  setActiveCommentId,
  textColor, // new prop (optional)
}) => {
  const formatDate = (dateString) => {
    return moment(dateString).fromNow();
  };

  return (
    <div>
      {/* Comments List */}
      <IonList>
        {comments && comments.length > 0 ? (
          comments.map((comment) => (
            <IonItem key={comment._id} lines="none">

              <IonLabel>
                <IonGrid>
                  <IonRow>
                    {/* Commenter Name */}
                    <IonCol size="8">
                      <IonText color="primary">
                        <strong>
                          {comment.user
                            ? `${comment.user.firstName} ${comment.user.lastName}`
                            : 'Anonymous'}
                        </strong>
                      </IonText>
                    </IonCol>
                    {/* Timestamp */}
                    <IonCol size="4" className="ion-text-right">
                      <IonText color="medium">
                        <p className="comment-date">{formatDate(comment.createdAt)}</p>
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    {/* Comment Text */}
                    <IonCol>
                      <IonText color="dark">
                        <p>{comment.text}</p>
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonLabel>
            </IonItem>
          ))
        ) : (
          <IonItem lines="none">
            <IonLabel>
              <IonText color="medium">No comments yet.</IonText>
            </IonLabel>
          </IonItem>
        )}
      </IonList>

      {/* Add Comment Form */}
      <div className="comment-form">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonTextarea
                value={commentText}
                onIonChange={(e) => setCommentText(e.detail.value)}
                placeholder="Enter your comment"
                autoGrow
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                color="primary"
                onClick={() => handleAddComment(goalId)}
                disabled={!commentText.trim()}
                aria-label="Add Comment"
                title="Add Comment"
              >
                <IonIcon slot="start" icon={chatbubbleEllipsesOutline} />
                Add Comment
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default CommentSection;
