import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSpinner,
  IonList,
  IonButton,
  IonIcon,
  IonMenu,
  IonSplitPane,
  IonMenuButton,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from '@ionic/react';
import { menu } from 'ionicons/icons';

import AppFooter from './AppFooter';
import { fetchFeed, fetchUserTeams, addComment, addReward, changeStatus } from '../api/apiFeed';
import moment from 'moment-timezone';
import '../index.css';
import FeedControls from './FeedControls';
import TeamSidebar from './TeamSidebar';
import TableView from './TableView';
import SemanticFeedEvent from './FeedEvent';

const FeedPage = () => {
  const [feedItems, setFeedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [commentText, setCommentText] = useState('');
  const [rewardAmount, setRewardAmount] = useState('');
  const [activeCommentId, setActiveCommentId] = useState(null);
  const [activeRewardId, setActiveRewardId] = useState(null);
  const [view, setView] = useState('list');
  const [sortActive, setSortActive] = useState(false);
  const [teamActive, setTeamActive] = useState(false);
  const [teams, setTeams] = useState([]);
  const { organization } = useParams();
  const organizationName = organization.replace(/-/g, ' ');
  const history = useHistory();

  const formatDate = (dateString) => {
    return moment(dateString).fromNow();
  };

  const formatTimeRemaining = (dateString) => {
    const now = moment();
    const targetDate = moment.tz(dateString, 'America/Los_Angeles').endOf('day');
    const duration = moment.duration(targetDate.diff(now));
    const days = duration.asDays();
    const hours = duration.asHours();

    if (days < -1) {
      return `Due ${Math.abs(Math.ceil(days))} days ago`;
    } else if (days < 0) {
      return `Due ${Math.abs(Math.ceil(hours))} hours ago`;
    } else if (days >= 1) {
      return `Due in ${Math.floor(days)} days`;
    } else {
      return `Due in ${Math.floor(hours)} hours`;
    }
  };

  const handleAddComment = async (goalId) => {
    try {
      console.log("Adding comment to goal:", goalId);
      const newComment = await addComment(goalId, commentText);
      setFeedItems((prevItems) =>
        prevItems.map((item) =>
          item.goalId._id === goalId ? { ...item, comments: [...item.comments, newComment] } : item
        )
      );
      setCommentText('');
      setActiveCommentId(null);
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleAddReward = async (goalId) => {
    try {
      console.log("Adding reward to goal:", goalId);
      const newReward = await addReward(goalId, rewardAmount);
      setFeedItems((prevItems) =>
        prevItems.map((item) =>
          item.goalId._id === goalId ? { ...item, rewards: [...item.rewards, newReward] } : item
        )
      );
      setRewardAmount('');
      setActiveRewardId(null);
    } catch (error) {
      console.error('Error adding reward:', error);
    }
  };

  const handleToggleComments = (goalId) => {
    setActiveCommentId(activeCommentId === goalId ? null : goalId);
    if (activeRewardId === goalId) setActiveRewardId(null);
  };

  const handleToggleRewards = (goalId) => {
    setActiveRewardId(activeRewardId === goalId ? null : goalId);
    if (activeCommentId === goalId) setActiveCommentId(null);
  };

  const handleStatusChange = async (goalId, newStatus) => {
    try {
      await changeStatus(goalId, newStatus);
      setFeedItems((prevItems) =>
        prevItems.map((item) =>
          item.goalId._id === goalId
            ? { ...item, status: newStatus === 'Done' ? 'Pending Approval' : newStatus }
            : item
        )
      );
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const isTeamLeader = (associatedTeam) => {
    if (!associatedTeam || !associatedTeam.leader) {
      return false;
    }
    return associatedTeam.leader.some(
      (leader) => String(leader.memberId) === String(localStorage.getItem('userId'))
    );
  };

  const renderFeedItem = (item, index, array) => {
    const isLastItem = index === array.length -1;
    const userName = item.userId ? `${item.userId.firstName} ${item.userId.lastName}` : 'Unknown user';
    const goalTitle = item.goalId ? item.goalId.title : 'Unknown Goal';
    const targetDate = item.goalId ? item.goalId.targetCompletionDate : null;
    const commentCount = item.comments ? item.comments.length : 0;
    const rewardSum = item.rewards ? item.rewards.reduce((sum, reward) => sum + reward.amount, 0) : 0;

    const goalTypeText = item.goalId.type === 'Team' ? 'created a new team goal:' : 'created a new goal:';

    if (view === 'table') {
      return (
        <TableView
          key={item._id}
          item={item}
          index={index}
          array={array}
          handleToggleComments={handleToggleComments}
          handleToggleRewards={handleToggleRewards}
          handleStatusChange={handleStatusChange}
          activeCommentId={activeCommentId}
          activeRewardId={activeRewardId}
          setActiveCommentId={setActiveCommentId}
          setActiveRewardId={setActiveRewardId}
          commentText={commentText}
          setCommentText={setCommentText}
          rewardAmount={rewardAmount}
          setRewardAmount={setRewardAmount}
          handleAddComment={handleAddComment}
          handleAddReward={handleAddReward}
          formatDate={formatDate}
          formatTimeRemaining={formatTimeRemaining}
          isTeamLeader={isTeamLeader}
        />
      );
    } else {
      return (
        <SemanticFeedEvent
          key={item._id}
          item={item}
          index={index}
          array={array}
          handleToggleComments={handleToggleComments}
          handleToggleRewards={handleToggleRewards}
          handleStatusChange={handleStatusChange}
          activeCommentId={activeCommentId}
          activeRewardId={activeRewardId}
          setActiveCommentId={setActiveCommentId}
          setActiveRewardId={setActiveRewardId}
          commentText={commentText}
          setCommentText={setCommentText}
          rewardAmount={rewardAmount}
          setRewardAmount={setRewardAmount}
          handleAddComment={handleAddComment}
          handleAddReward={handleAddReward}
          formatDate={formatDate}
          formatTimeRemaining={formatTimeRemaining}
          isTeamLeader={isTeamLeader}
        />
      );
    }
  };

  const handleCreateGoalClick = () => {
    history.push('/create-goal'); // Use history.push instead of navigate
  };

  const handleViewTeamsClick = () => {
    history.push('/teams'); // Use history.push instead of navigate
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchFeed(organizationName);
        setFeedItems(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchTeamsData = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const activeOrgId = localStorage.getItem('activeOrganization');
        const teamsData = await fetchUserTeams(userId, activeOrgId);
        setTeams(teamsData);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };

    fetchData();
    fetchTeamsData();
  }, [organizationName]);

  if (loading) {
    return (
      <IonPage>
        <IonSplitPane contentId="main">
          <IonMenu contentId="main" type="overlay">
            <TeamSidebar teams={teams} />
          </IonMenu>

          <IonPage id="main">
            <IonHeader>
              <IonToolbar
              style={{
                '--background': 'linear-gradient(0deg, #479d9d, #006d77)',
              }}
              >
                <IonButtons slot="start">
                  <IonMenuButton />
                </IonButtons>
                <IonTitle>{organizationName} Feed</IonTitle>
              </IonToolbar>
            </IonHeader>

            <IonContent>
              <div >
                <IonSpinner name="crescent" />
                <p>Loading feed...</p>
              </div>
            </IonContent>

            <AppFooter />
          </IonPage>
        </IonSplitPane>
      </IonPage>
    );
  }

  if (error) {
    return (
      <IonPage>
        <IonSplitPane contentId="main">
          <IonMenu contentId="main" type="overlay">
            <TeamSidebar teams={teams} />
          </IonMenu>

          <IonPage id="main">
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonMenuButton />
                </IonButtons>
                <IonTitle>{organizationName} Feed</IonTitle>
              </IonToolbar>
            </IonHeader>

            <IonContent>
              <div>
                <p>Error loading feed: {error}</p>
              </div>
            </IonContent>

            <AppFooter />
          </IonPage>
        </IonSplitPane>
      </IonPage>
    );
  }

  return (
    <IonPage>
      <IonSplitPane contentId="main">
        <IonMenu contentId="main" type="overlay">
          <TeamSidebar teams={teams} />
        </IonMenu>

        <IonPage id="main">
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonMenuButton />
              </IonButtons>
              <IonTitle>{organizationName} Feed</IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <div >
              <FeedControls
                view={view}
                setView={setView}
                sortActive={sortActive}
                setSortActive={setSortActive}
                teamActive={teamActive}
                setTeamActive={() => {
                  setTeamActive(!teamActive);
                }}
              />

              {feedItems.length > 0 ? (
                <IonList>
                  {feedItems.map((item, index, array) => renderFeedItem(item, index, array))}
                </IonList>
              ) : (
                <div >
                  <IonGrid>
                    <IonRow className="ion-justify-content-center">
                      <IonCol size="12" size-md="8" size-lg="6">
                        <IonCard>
                          <IonCardHeader>
                            <IonCardTitle className="ion-text-center">
                              Welcome to Your Feed
                            </IonCardTitle>
                          </IonCardHeader>
                          <IonCardContent className="ion-text-center">
                            It looks like you don't have any goals yet. Get started by creating a new goal or viewing your teams.
                          </IonCardContent>
                          <IonGrid>
                            <IonRow className="ion-justify-content-center">
                              <IonButton expand="block" onClick={handleCreateGoalClick} style={{ margin: '0.5em' }}>
                                Create Goal
                              </IonButton>
                              <IonButton
                                expand="block"
                                color="secondary"
                                onClick={handleViewTeamsClick}
                                style={{ margin: '0.5em' }}
                              >
                                View Your Teams
                              </IonButton>
                            </IonRow>
                          </IonGrid>
                        </IonCard>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              )}
            </div>
          </IonContent>

          <AppFooter />
        </IonPage>
      </IonSplitPane>
    </IonPage>
  );
};

export default FeedPage;
