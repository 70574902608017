import { authenticatedFetch } from './api';

export const sendInvite = async (formData) => {
  try {
    const response = await authenticatedFetch(`/api/invite`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });
    return response;
  } catch (error) {
    throw new Error('There was an error sending the invite: ' + error.message);
  }
};

export const fetchPendingMembers = async (orgID) => {
    try {
      const response = await authenticatedFetch(`/api/organization/${orgID}/pending`);
      return response;
    } catch (error) {
      throw new Error('There was an error fetching the pending members: ' + error.message);
    }
  };
  
  export const deletePendingMember = async (memberId) => {
    try {
      const response = await authenticatedFetch(`/api/invite/${memberId}`, {
        method: 'DELETE'
      });
      return response;
    } catch (error) {
      throw new Error('There was an error deleting the member: ' + error.message);
    }
  };
  
  export const updatePendingMemberRole = async (memberId, newRole) => {
    try {
      const response = await authenticatedFetch(`/api/invite/${memberId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ role: newRole })
      });
      return response;
    } catch (error) {
      throw new Error('There was an error updating the member role: ' + error.message);
    }
  };