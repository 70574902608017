import React, { useState } from "react";
import {
  IonButton,
  IonInput,
  IonLabel,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonItem,
  IonList,
  IonLoading,
  IonAlert,
  IonText,
} from '@ionic/react';
import { useHistory } from "react-router-dom";

const Signup = ({ mobile }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [titleRole, setTitleRole] = useState('');
  const [phone, setPhone] = useState('');
  const [organization, setOrganization] = useState('');
  const [passwordValid, setPasswordValid] = useState(true);
  const [signupError, setSignupError] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const history = useHistory();

  const validatePassword = (pwd) => {
    const hasUppercase = /[A-Z]/.test(pwd);
    const hasLowercase = /[a-z]/.test(pwd);
    const hasNumbers = /\d/.test(pwd);
    const hasNonAlphaNumeric = /\W/.test(pwd);
    const isLengthValid = pwd.length >= 8;

    return hasUppercase && hasLowercase && hasNumbers && hasNonAlphaNumeric && isLengthValid;
  };

  const handlePasswordChange = (e) => {
    const pwd = e.detail.value;
    setPassword(pwd);
    setPasswordValid(validatePassword(pwd));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoading(true);

    const formData = {
      email,
      password,
      firstName,
      lastName,
      titleRole,
      phone,
      organization,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (response.ok && data.token) {
        localStorage.setItem('token', data.token);
        setSignupError(null);
        localStorage.setItem('activeOrganization', data.activeOrganization);
        localStorage.setItem('userId', data.userId);
        history.push("/invite-users", { state: { orgID: data.organization._id } });
      } else {
        setSignupError(data.error);
        setShowAlert(true);
        setAlertMessage(data.error);
      }
    } catch (error) {
      setSignupError('There was an error!');
      setShowAlert(true);
      setAlertMessage('There was an error!');
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Sign Up</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div style={{
          width: "100%",
          minHeight: "100vh",
          background: 'linear-gradient(-45deg, #006d77, #83c5be)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: mobile ? '1em' : '2em',
        }}>
          <IonText color="light" style={{ fontSize: mobile ? '2.5em' : '4em', fontWeight: 'normal', marginBottom: '0.5em' }}>
            Cochago
          </IonText>
          <IonList style={{ width: '100%', maxWidth: '500px', backgroundColor: 'rgba(255, 255, 255, 0.9)', padding: '2em', borderRadius: '8px' }}>
            <form onSubmit={handleSubmit}>
              <IonItem>
                <IonInput
                  labelPlacement="floating"
                  label="Email"
                  type="email"
                  value={email}
                  onIonChange={(e) => setEmail(e.detail.value)}
                  required
                />
              </IonItem>
              <IonItem>
                <IonInput
                  labelPlacement="floating"
                  label="Password"
                  type="password"
                  value={password}
                  onIonChange={handlePasswordChange}
                  required
                />
                {!passwordValid && (
                  <IonText color="danger" style={{ fontSize: '0.8em' }}>
                    Password must be at least 8 characters long and contain an uppercase letter, a lowercase letter, a number, and a special character.
                  </IonText>
                )}
              </IonItem>
              <IonItem>
                <IonInput
                  labelPlacement="floating"
                  label="First Name"
                  value={firstName}
                  onIonChange={(e) => setFirstName(e.detail.value)}
                  required
                />
              </IonItem>
              <IonItem>
                <IonInput
                  labelPlacement="floating"
                  label="Last Name"
                  value={lastName}
                  onIonChange={(e) => setLastName(e.detail.value)}
                  required
                />
              </IonItem>
              <IonItem>
                <IonInput
                  labelPlacement="floating"
                  label="Phone Number"
                  value={phone}
                  onIonChange={(e) => setPhone(e.detail.value)}
                  required
                />
              </IonItem>
              <IonItem>
                <IonInput
                  labelPlacement="floating"
                  label="Organization"
                  value={organization}
                  onIonChange={(e) => setOrganization(e.detail.value)}
                  required
                />
              </IonItem>
              <IonItem>
                <IonInput
                  labelPlacement="floating"
                  label="Title/Role"
                  value={titleRole}
                  onIonChange={(e) => setTitleRole(e.detail.value)}
                  required
                />
              </IonItem>
              {signupError && <IonText color="danger">{signupError}</IonText>}
              <IonButton expand="block" type="submit" style={{ marginTop: '1em' }} disabled={!passwordValid}>
                Sign Up
              </IonButton>
            </form>
          </IonList>
        </div>
        <IonLoading isOpen={showLoading} message={'Signing up...'} />
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'Error'}
          message={alertMessage}
          buttons={['OK']}
        />
      </IonContent>
    </IonPage>
  );
};

export default Signup;
