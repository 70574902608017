import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonLabel,
  IonText,
  IonLoading,
  IonIcon,
  IonAccordionGroup,
  IonAccordion
} from '@ionic/react';
import { trash, removeCircle } from 'ionicons/icons';
import {
  fetchOrganizationDetailsForTeamsList,
  fetchTeamsForTeamsList,
  deleteTeamForTeamsList,
  addSingleMemberForTeamsList,
  removeMemberForTeamsList
} from '../api/apiTeam';

export default function TeamsList({ teamsUpdatedAt, setTeamsUpdatedAt, orgId }) {
  const [teams, setTeams] = useState([]);
  const [usersAndInvitesList, setUsersAndInvitesList] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const userList = await fetchOrganizationDetailsForTeamsList(orgId);
      setUsersAndInvitesList(userList);

      const teamsData = await fetchTeamsForTeamsList();
      setTeams(teamsData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [teamsUpdatedAt]); // Depend on teamsUpdatedAt

  const handleDeleteTeam = async (teamId) => {
    try {
      const response = await deleteTeamForTeamsList(teamId);
      if (response && response.message === 'Team deleted successfully') {
        const updatedTeams = teams.filter(team => team._id !== teamId);
        setTeams(updatedTeams);
        setTeamsUpdatedAt(Date.now()); // Notify CreateTeams about the update
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleAddSingleMember = async (teamId, userId) => {
    const userToAdd = usersAndInvitesList.find(user => user._id === userId);
    try {
      await addSingleMemberForTeamsList(teamId, userToAdd);
      fetchData();
    } catch (error) {
      console.error('Error adding member:', error);
    }
  };

  const handleRemoveMember = async (teamId, userId) => {
    const memberId = typeof userId === 'object' ? userId._id : userId;

    try {
      const response = await removeMemberForTeamsList(teamId, memberId);
      if (response && response.message === 'User removed from team successfully.') {
        setTeams(prevTeams => prevTeams.map(team => {
          if (team._id === teamId) {
            return {
              ...team,
              members: team.members.filter(member => member.memberId._id !== memberId)
            };
          }
          return team;
        }));
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getAvailableUsersAndInvites = (team) => {
    const teamMembersIds = team.members
      .filter(member => member.memberId)
      .map(member => member.memberId._id.toString());
    return usersAndInvitesList.filter(item => !teamMembersIds.includes(item._id.toString()));
  };

  const getLeaderName = (team) => {
    const leaderId = team.leader[0].memberId;
    const leaderMember = team.members.find(member => member.memberId._id === leaderId);
    if (leaderMember) {
      if (leaderMember.memberType === 'User') {
        return `${leaderMember.memberId.firstName} ${leaderMember.memberId.lastName}`;
      } else {
        return leaderMember.memberId.name;
      }
    }
    return 'Leader';
  };

  if (loading) {
    return (
      <IonLoading isOpen={loading} message={'Loading...'} />
    );
  }

  return (
    <div style={{
      width: '100%',
      padding: '0 1em',
      marginTop: '2em',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <IonText style={{
        textAlign: "center",
        color: 'white',
        fontSize: '2em',
        fontWeight: 'normal',
        marginBottom: '1em',
        textShadow: '2px 2px 4px rgba(0,0,0,0.1)'
      }}>Teams</IonText>
      <IonAccordionGroup style={{ width: '100%' }}>
        {teams.map(team => {
          const availableUsersAndInvites = getAvailableUsersAndInvites(team);
          return (
            <IonAccordion key={team._id} value={team._id} style={{ width: '100%' }}>
              <IonItem slot="header" style={{ width: '100%' }}>
                <IonLabel>
                  <h2>{team.name}</h2>
                  <p>Leader: {getLeaderName(team)}</p>
                </IonLabel>
                <IonIcon
                  slot="end"
                  icon={trash}
                  color="danger"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent accordion toggle
                    if (window.confirm("Are you sure you want to delete this team?")) {
                      handleDeleteTeam(team._id);
                    }
                  }}
                />
              </IonItem>
              <div className="ion-padding" slot="content">
                {team.members
                  .sort((a, b) => (team.leader[0].memberId === a.memberId._id ? -1 : 1))
                  .map(member => (
                    <IonItem key={member.memberId?._id}>
                      <IonLabel>
                        {member.memberType === 'User' && member.memberId ?
                          `${member.memberId.firstName} ${member.memberId.lastName}`
                          : member.memberId?.name}
                        {team.leader[0].memberId === member.memberId._id && ' (Leader)'}
                      </IonLabel>
                      {team.leader[0].memberId !== member.memberId._id && (
                        <IonIcon
                          slot="end"
                          icon={removeCircle}
                          color="medium"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            const memberId = member.memberId._id;
                            handleRemoveMember(team._id, memberId);
                          }}
                        />
                      )}
                    </IonItem>
                  ))}
                {availableUsersAndInvites.length > 0 && (
                  <IonItem>
                    <IonLabel>Add Member</IonLabel>
                    <IonSelect
                      placeholder='Select Member'
                      onIonChange={(e) => handleAddSingleMember(team._id, e.detail.value)}
                    >
                      {availableUsersAndInvites.map(item => (
                        <IonSelectOption key={item._id} value={item._id}>{item.name}</IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                )}
              </div>
            </IonAccordion>
          );
        })}
      </IonAccordionGroup>
    </div>
  );
}
