import React from 'react';
import {
  IonList,
  IonItem,
  IonLabel,
  IonMenuToggle,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';

const TeamSidebar = ({ teams }) => {
  const history = useHistory();

  const handleTeamClick = (team) => {
    // Navigate to the team's page or perform desired action
    history.push(`/team/${team.key}`);
  };

  return (
    <>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Select a Team</IonTitle>
          <IonMenuToggle slot="end">
            <IonButton fill="clear">
              <IonIcon icon={close} />
            </IonButton>
          </IonMenuToggle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {teams.map((team) => (
            <IonMenuToggle key={team.key} autoHide={false}>
              <IonItem button onClick={() => handleTeamClick(team)}>
                <IonLabel>{team.text}</IonLabel>
              </IonItem>
            </IonMenuToggle>
          ))}
        </IonList>
      </IonContent>
    </>
  );
};

export default TeamSidebar;
