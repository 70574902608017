import React from 'react';
import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonIcon,
  IonText,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/react';
import { cashOutline, personCircleOutline } from 'ionicons/icons';

const RewardSection = ({
  goalId,
  rewards,
  rewardAmount,
  setRewardAmount,
  handleAddReward,
}) => {
  return (
    <div>
      <IonList>
        {rewards && rewards.length > 0 ? (
          rewards.map((reward) => (
            <IonItem key={reward._id}>
              <IonLabel>
                <IonText color="primary">
                  <strong>
                    {reward.user
                      ? `${reward.user.firstName} ${reward.user.lastName}`
                      : 'Anonymous'}
                  </strong>
                </IonText>
              </IonLabel>
              <IonLabel slot="end">
                <IonText color="secondary">
                  <strong>${reward.amount}</strong>
                </IonText>
              </IonLabel>
            </IonItem>
          ))
        ) : (
          <IonItem>
            <IonLabel>
              <IonText color="medium">No rewards yet.</IonText>
            </IonLabel>
          </IonItem>
        )}
      </IonList>

      <div className="reward-form">
        <IonGrid>
          <IonRow>
            <IonCol size="9">
              <IonItem>
                <IonInput
                  value={rewardAmount}
                  onIonChange={(e) => setRewardAmount(e.detail.value)}
                  placeholder="Enter reward amount"
                  type="number"
                />
              </IonItem>
            </IonCol>
            <IonCol size="3">
              <IonButton
                expand="block"
                color="primary"
                onClick={() => handleAddReward(goalId)}
              >
                <IonIcon slot="start" icon={cashOutline} />
                Add
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default RewardSection;
