import React from 'react';
import { IonToolbar, IonButtons, IonButton, IonIcon } from '@ionic/react';
import { list, grid, apps, swapVertical, people } from 'ionicons/icons';

const FeedControls = ({ view, setView, sortActive, setSortActive, teamActive, setteamActive }) => {
  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonButton
          onClick={() => setView('list')}
          color={view === 'list' ? 'primary' : 'medium'}
        >
          <IonIcon icon={list} />
        </IonButton>
        <IonButton
          onClick={() => setView('table')}
          color={view === 'table' ? 'primary' : 'medium'}
        >
          <IonIcon icon={grid} />
        </IonButton>
        <IonButton
          onClick={() => setView('columns')}
          color={view === 'columns' ? 'primary' : 'medium'}
        >
          <IonIcon icon={apps} />
        </IonButton>
      </IonButtons>
      <IonButtons slot="end">
        <IonButton
          onClick={() => setSortActive(!sortActive)}
          color={sortActive ? 'primary' : 'medium'}
        >
          <IonIcon icon={swapVertical} />
        </IonButton>
        <IonButton
          onClick={setteamActive}
          color={teamActive ? 'primary' : 'medium'}
        >
          <IonIcon icon={people} />
        </IonButton>
      </IonButtons>
    </IonToolbar>
  );
};

export default FeedControls;
