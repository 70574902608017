import React, { useState } from 'react';
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonFooter,
  IonInput,
  IonList,
  IonItem,
} from '@ionic/react';
import { menuOutline, arrowForwardOutline, mailOutline } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import '../HomePage.css'; // Ensure this file includes the necessary styling

const HomePage = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');

  const handleSubscribe = () => {
    if (email) {
      // Implement your subscription logic here
      alert(`Thank you for subscribing with ${email}!`);
      setEmail('');
    } else {
      alert('Please enter a valid email address.');
    }
  };

  return (
    <IonPage>
      {/* Main Content */}
      <IonContent fullscreen>
        {/* Hero Section */}
        <div className="hero-section">
          <IonGrid className="content-container">
            <IonRow className="ion-justify-content-center">
              <IonCol size="12" sizeMd="8" className="ion-text-center">
                <h1 className="hero-title">Cochago</h1>
                <h2 className="hero-subtitle">Do More Together</h2>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol size="12" sizeMd="6" className="ion-text-center">
                <IonButton
                  shape="round"
                  expand="block"
                  className="get-started-button"
                  onClick={() => history.push('/signup')}
                >
                  Build Your Team
                  <IonIcon icon={arrowForwardOutline} slot="end" />
                </IonButton>
                <IonButton
                  shape="round"
                  expand="block"
                  fill="outline"
                  color="light"
                  className="login-button"
                  onClick={() => history.push('/login')}
                >
                  Login
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        {/* Features Section */}
        <div className="features-section">
          <IonGrid className="content-container">
            <IonRow className="ion-justify-content-center">
              <IonCol size="12" sizeMd="6" className="ion-text-center">
                <IonCard className="feature-card">
                  <IonCardHeader>
                    <IonCardTitle>Unify Your Team's Vision</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <p>Cochago empowers your organization with a structured approach to set, track, and reward goals. Our platform brings teams together, helping them understand the bigger picture and work towards shared objectives seamlessly.</p> 
                    <IonButton
                    color="primary"
                    expand="block"
                    onClick={() => history.push('/signup')}
                    className="cta-button"
                  >
                    Build Your Team
                  </IonButton>
                  </IonCardContent>
                </IonCard>
              </IonCol>

              <IonCol size="12" sizeMd="6" className="ion-text-center">
                <IonCard className="feature-card">
                  <IonCardHeader>
                    <IonCardTitle>Transform Achievements into Success</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <p>Every accomplishment, big or small, contributes to the larger goals of the organization. Celebrate individual milestones and see how they ladder up to drive overall success.</p>
                    <IonButton
                    color="primary"
                    expand="block"
                    fill="outline"
                    onClick={() => history.push('/signup')}
                    className="cta-button"
                  >
                    Join Today
                  </IonButton>
                  </IonCardContent>

                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        {/* Supporting Research Section */}
        <div className="research-section">
          <IonGrid className="content-container">
            <IonRow className="ion-justify-content-center">
              <IonCol size="12" className="ion-text-center">
                <div className="section-divider">
                  <IonText>Supporting Research</IonText>
                </div>
              </IonCol>
            </IonRow>

            <IonRow className="ion-justify-content-center">
              <IonCol size="12" sizeMd="4">
                <IonCard className="research-card">
                  <IonCardHeader>
                    <IonCardTitle>Employee Recognition and Performance: The Role of Pride (2022)</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <p>This study reveals that recognizing employees' achievements fosters both pride and increased task performance. Meaningful recognition not only motivates individuals to achieve their goals but also creates a strong organizational culture by encouraging greater involvement and cooperation.</p>
                    <IonButton href="https://www.mdpi.com/2071-1050/14/3/1631" target="_blank" size="small">
                      Learn more
                    </IonButton>
                  </IonCardContent>

                </IonCard>
              </IonCol>

              <IonCol size="12" sizeMd="4">
                <IonCard className="research-card">
                  <IonCardHeader>
                    <IonCardTitle>Goal Setting and Organizational Success: Motivation through Rewards (2023)</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                      <p>Research supports that specific, challenging goals paired with rewards lead to improved productivity and performance. By creating a sense of achievement and commitment to the organization's vision, these strategies foster a culture of high performance and continuous engagement.</p>
                    <IonButton href="https://www.researchgate.net/publication/363025052" target="_blank" size="small">
                      Learn more
                    </IonButton>
                  </IonCardContent>
                </IonCard>
              </IonCol>

              <IonCol size="12" sizeMd="4">
                <IonCard className="research-card">
                  <IonCardHeader>
                    <IonCardTitle>Boosting Organizational Performance through Motivation (2023)</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <p>Intrinsic and extrinsic motivation play crucial roles in enhancing workplace performance. This research highlights how meaningful work and external rewards—like financial incentives—create a self-rewarding loop, boosting job satisfaction, creativity, and overall success within teams.</p>
                    <IonButton href="https://ijbeg.org/publication/v6i1a3/" target="_blank" size="small">
                      Learn more
                    </IonButton>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        {/* Footer */}
        <IonFooter>
          <div className="footer">
            <IonGrid className="content-container">
              <IonRow>
                <IonCol sizeMd="3" sizeXs="12" className="footer-section">
                  <h4>About</h4>
                  <IonList>
                    <IonItem button href='https://blog.cochago.com/about'>
                      <IonText>About</IonText>
                    </IonItem>
                    <IonItem button onClick={() => history.push('/contact')}>
                      <IonText>Contact Us</IonText>
                    </IonItem>
                  </IonList>
                </IonCol>

                <IonCol sizeMd="7" sizeXs="12" className="footer-section">
                  <h4>Stay Connected with Cochago</h4>
                  <p>
                    Join our newsletter for the latest updates, features, and success stories. Let's achieve more, together!
                  </p>
                  <div className="subscribe-form">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="9" sizeMd="10">
                          <IonInput
                            placeholder="Enter your email..."
                            type="email"
                            value={email}
                            onIonChange={(e) => setEmail(e.detail.value)}
                          />
                        </IonCol>
                        <IonCol size="3" sizeMd="2">
                          <IonButton onClick={handleSubscribe} fill="solid">
                            <IonIcon icon={mailOutline} />
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default HomePage;
