import { createMedia } from '@artsy/fresnel';
import React, {useState, useRef, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';


const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

const styles = {
  feedWrapper: {
    height: 'calc(100vh  - 72px)',
    backgroundColor: '#479d9d',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
};

AppHeader.propTypes = {
  children: PropTypes.node,
};

const teams = [
    { key: 'all', text: 'All', value: 'all' },
    { key: 'team1', text: 'Team 1', value: 'team1' },
    { key: 'team2', text: 'Team 2', value: 'team2' },
    // ... add more teams if needed
];

const individuals = [
    { key: 'all', text: 'All', value: 'all' },
    { key: 'ind1', text: 'Individual 1', value: 'ind1' },
    { key: 'ind2', text: 'Individual 2', value: 'ind2' },
    // ... add more individuals if needed
];


const columns = [
  {
    title: 'Past Due',
    cards: [
      { header: 'Task 1', date: '10-23-2023', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec in efficitur leo. In hac habitasse platea dictumst. Sed ullamcorper, nulla ut mollis posuere, risus elit tempor ligula, a luctus erat lorem non est. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec sed tincidunt lacus. Duis vitae facilisis velit.' },
      //... other cards
    ]
  },
  {
    title: 'To Do',
    cards: [
      { header: 'Task 2', date: '10-23-2023',reward: "Lunch Voucher", description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec in efficitur leo. In hac habitasse platea dictumst. Sed ullamcorper, nulla ut mollis posuere, risus elit tempor ligula, a luctus erat lorem non est. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec sed tincidunt lacus. Duis vitae facilisis velit.' },
      //... other cards
    ]
  },
  {
    title: 'Doing',
    cards: [
      { header: 'Task 3', date: '10-23-2023',reward: "Lunch Voucher", description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec in efficitur leo. In hac habitasse platea dictumst. Sed ullamcorper, nulla ut mollis posuere, risus elit tempor ligula, a luctus erat lorem non est. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec sed tincidunt lacus. Duis vitae facilisis velit.' },
      //... other cards
    ]
  },
  {
    title: 'Done',
    cards: [
      { header: 'Task 4', date: '10-23-2023',reward: "Lunch Voucher", description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec in efficitur leo. In hac habitasse platea dictumst. Sed ullamcorper, nulla ut mollis posuere, risus elit tempor ligula, a luctus erat lorem non est. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec sed tincidunt lacus. Duis vitae facilisis velit.' },
      { header: 'Task 5', date: '10-23-2023',reward: "Lunch Voucher",description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec in efficitur leo. In hac habitasse platea dictumst. Sed ullamcorper, nulla ut mollis posuere, risus elit tempor ligula, a luctus erat lorem non est. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec sed tincidunt lacus. Duis vitae facilisis velit.' },
      { header: 'Task 6', date: '10-23-2023',reward: "Lunch Voucher", description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec in efficitur leo. In hac habitasse platea dictumst. Sed ullamcorper, nulla ut mollis posuere, risus elit tempor ligula, a luctus erat lorem non est. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec sed tincidunt lacus. Duis vitae facilisis velit.' },
      { header: 'Task 7', date: '10-23-2023',reward: "Lunch Voucher", description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec in efficitur leo. In hac habitasse platea dictumst. Sed ullamcorper, nulla ut mollis posuere, risus elit tempor ligula, a luctus erat lorem non est. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec sed tincidunt lacus. Duis vitae facilisis velit.' },
      { header: 'Task 8', date: '10-23-2023',reward: "Lunch Voucher", description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec in efficitur leo. In hac habitasse platea dictumst. Sed ullamcorper, nulla ut mollis posuere, risus elit tempor ligula, a luctus erat lorem non est. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec sed tincidunt lacus. Duis vitae facilisis velit.' },
      { header: 'Task 9', date: '10-23-2023',reward: "Lunch Voucher", description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec in efficitur leo. In hac habitasse platea dictumst. Sed ullamcorper, nulla ut mollis posuere, risus elit tempor ligula, a luctus erat lorem non est. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec sed tincidunt lacus. Duis vitae facilisis velit.' },
      { header: 'Task 10', date: '10-23-2023',reward: "Lunch Voucher", description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec in efficitur leo. In hac habitasse platea dictumst. Sed ullamcorper, nulla ut mollis posuere, risus elit tempor ligula, a luctus erat lorem non est. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec sed tincidunt lacus. Duis vitae facilisis velit.' },
      { header: 'Task 11', date: '10-23-2023',reward: "Lunch Voucher", description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec in efficitur leo. In hac habitasse platea dictumst. Sed ullamcorper, nulla ut mollis posuere, risus elit tempor ligula, a luctus erat lorem non est. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec sed tincidunt lacus. Duis vitae facilisis velit.' },
      { header: 'Task 12', date: '10-23-2023',reward: "Lunch Voucher", description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec in efficitur leo. In hac habitasse platea dictumst. Sed ullamcorper, nulla ut mollis posuere, risus elit tempor ligula, a luctus erat lorem non est. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec sed tincidunt lacus. Duis vitae facilisis velit.' },
      { header: 'Task 13', date: '10-23-2023',reward: "Lunch Voucher", description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec in efficitur leo. In hac habitasse platea dictumst. Sed ullamcorper, nulla ut mollis posuere, risus elit tempor ligula, a luctus erat lorem non est. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec sed tincidunt lacus. Duis vitae facilisis velit.' },
      //... other cards
    ]
  },
];

const DraggableCard = ({ card, index, moveCard, columnIndex, columnsData, setColumnsData }) => {
  const [rewardInput, setRewardInput] = useState(card.reward || "");

  const saveReward = () => {
      // 1. Make a copy of the columnsData state
      const updatedColumns = [...columnsData];

      // 2. Navigate to the card that's being edited
      const targetCard = updatedColumns[columnIndex].cards[index];

      // 3. Update the reward property of that card
      targetCard.reward = rewardInput;

      // 4. Update the columnsData state with the modified copy
      setColumnsData(updatedColumns);

      // 5. Close the modal
      toggleModal();
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!isModalOpen);

  const [dropdownValue, setDropdownValue] = useState(columnIndex);

  const [, dragRef] = useDrag({
    type: 'CARD',
    item: { card, index, columnIndex },
  });

  const [, dropRef] = useDrop({
    accept: 'CARD',
    hover: (draggedItem) => {
      if (draggedItem.columnIndex !== columnIndex || draggedItem.index !== index) {
        moveCard(draggedItem.columnIndex, draggedItem.index, columnIndex, index);
        draggedItem.index = index;  // Resetting to current values
        draggedItem.columnIndex = columnIndex; // Resetting to current values
      }
    },
  });



const truncatedDescription = card.description.length > 80
  ? card.description.substring(0, 80) + "..."
  : card.description;

  const combinedRef = (node) => {
    dragRef(node);
    dropRef(node);
  };

  const statusOptions = columns.map((column, idx) => ({
    key: idx,
    text: column.title,
    value: idx
  }));

  const handleStatusChange = (e, { value }) => {
    if (value !== dropdownValue) {
      setDropdownValue(value); // Update the local state
      moveCard(columnIndex, index, value, columnsData[value].cards.length);
    }
  };

  return (
    <div ref={combinedRef}>
      <Card color='blue' fluid>
        <Card.Content>
          <Card.Header>
              {card.header}
              <div style={{ fontSize: '0.8em', marginTop: '5px' }}>{card.date}</div>
          </Card.Header>
          {card.reward ? (
              <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                  <Label color="green">
                      <Icon name='gift' /> {card.reward}
                  </Label>
              </div>
          ) : (
              <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                  <Label as='a' color="blue" onClick={toggleModal}>
                      <Icon name='plus' /> Add Reward
                  </Label>
              </div>
          )}
          <Card.Description>{truncatedDescription}</Card.Description>
        </Card.Content>
        <Grid>
          <Grid.Row style={{ margin: '1em' }}>
            <Grid.Column width={8} verticalAlign="middle" onClick={toggleModal}>
              <Icon name="plus" /><strong>Details</strong>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              <Dropdown
                inline
                options={statusOptions}
                value={dropdownValue}
                onChange={handleStatusChange}
                style={{ marginRight: '10px' }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>

      <Modal open={isModalOpen} onClose={toggleModal}>
        <Modal.Header>
          {card.header}
          <Icon name='edit' style={{ float: 'right' }} /> {/* Added the edit icon in the modal header */}
        </Modal.Header>
        <Modal.Content>
          <p>{card.description}</p>
          <div style={{ marginTop: '20px' }}>
            <Label>Reward:</Label>
            <Input
              value={rewardInput}
              onChange={e => setRewardInput(e.target.value)}
              placeholder="Specify a reward..."
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={saveReward}>Save Reward</Button>
          <Button onClick={toggleModal}>Close</Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

const MobileKanbanBoard = ({ columnsData, moveCard, setColumnsData, scrollingContainerRef }) => {
  const [lastScrollPos, setLastScrollPos] = useState(0);
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollingContainerRef.current) {
        const currentScrollPos = scrollingContainerRef.current.scrollTop;
        const isScrollingDown = currentScrollPos > lastScrollPos;
        setIsDropdownVisible(!isScrollingDown);
        setLastScrollPos(currentScrollPos);
      }
    };

    if (scrollingContainerRef.current) {
      scrollingContainerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollingContainerRef.current) {
        scrollingContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [lastScrollPos]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '1em 0',
        backgroundColor: '#479d9d',
        height: 'calc(100vh - 120px)',
        overflowX: 'scroll',
      }}
      ref={scrollingContainerRef}
    >
      {columnsData.map((column, columnIndex) => (
        <div key={columnIndex} style={{ flex: 1, padding: '0 1em', marginBottom: '2em' }}> {/* Added marginBottom here */}
          <Header as='h2' style={{ color: 'white' }}>{column.title}</Header>
          <Card.Group>
            {column.cards.map((card, cardIndex) => (
              <div style={{ width: '90%', margin: '.2em auto' }}>
                <DraggableCard
                  key={`${columnIndex}-${cardIndex}`}
                  card={card}
                  index={cardIndex}
                  columnIndex={columnIndex}
                  moveCard={moveCard}
                  columnsData={columnsData}
                  setColumnsData={setColumnsData} // Pass down setColumnsData
                />
              </div>
            ))}
          </Card.Group>
        </div>
      ))}
    </div>
);
};

const DesktopKanbanBoard = ({ columnsData, moveCard, setColumnsData, scrollingContainerRef }) => {
  const [lastScrollPos, setLastScrollPos] = useState(0);
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollingContainerRef.current) {
        const currentScrollPos = scrollingContainerRef.current.scrollTop;
        const isScrollingDown = currentScrollPos > lastScrollPos;
        setIsDropdownVisible(!isScrollingDown);
        setLastScrollPos(currentScrollPos);
      }
    };

    if (scrollingContainerRef.current) {
      scrollingContainerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollingContainerRef.current) {
        scrollingContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [lastScrollPos]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '1em 0',
        backgroundColor: '#479d9d',
        height: 'calc(100vh - 120px)',
        overflowX: 'scroll',
      }}
      ref={scrollingContainerRef}
    >
      {columnsData.map((column, columnIndex) => (
      <div key={columnIndex} style={{ flex: 1, padding: '0 1em', marginBottom: '1em' }}> {/* Added marginBottom here */}
        <Header as='h2' style={{ color: 'white' }}>{column.title}</Header>
        <Card.Group>
          {column.cards.map((card, cardIndex) => (
            <div style={{margin: '.2em auto'}}>
            <DraggableCard
              key={`${columnIndex}-${cardIndex}`}
              card={card}
              index={cardIndex}
              columnIndex={columnIndex}
              moveCard={moveCard}
              columnsData={columnsData}
              setColumnsData={setColumnsData}
            />
            </div>
          ))}
        </Card.Group>
      </div>
    ))}
  </div>
);
};

const NoScrollbarForBody = () => {
  useEffect(() => {
    document.body.classList.add('no-scrollbar');

    return () => {
      document.body.classList.remove('no-scrollbar');
    };
  }, []);

  return null; // this component doesn't render anything to the DOM
};


const KanbanBoard = () => {
  const [columnsData, setColumnsData] = useState(columns);
  const [forceRender, setForceRender] = useState(false);
  const [lastScrollPos, setLastScrollPos] = useState(0);


  const moveCard = (fromColumn, fromIndex, toColumn, toIndex) => {
    const updatedColumns = [...columnsData];
    const [movedCard] = updatedColumns[fromColumn].cards.splice(fromIndex, 1);
    updatedColumns[toColumn].cards.splice(toIndex, 0, movedCard);
    setColumnsData(updatedColumns);
    setForceRender(prev => !prev);
  };

  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedIndividual, setSelectedIndividual] = useState('');

  const scrollingContainerRef = useRef(null);

  // Define isDropdownVisible state here
const [isDropdownVisible, setIsDropdownVisible] = useState(true);

useEffect(() => {
  const handleScroll = () => {
    if (scrollingContainerRef.current) {
      const currentScrollPos = scrollingContainerRef.current.scrollTop;
      const isScrollingDown = currentScrollPos > lastScrollPos;
      setIsDropdownVisible(!isScrollingDown);
      setLastScrollPos(currentScrollPos);
    }
  };

  if (scrollingContainerRef.current) {
    scrollingContainerRef.current.addEventListener('scroll', handleScroll);
  }

  return () => {
    if (scrollingContainerRef.current) {
      scrollingContainerRef.current.removeEventListener('scroll', handleScroll);
    }
  };
}, [lastScrollPos]);

  return (
    <MediaContextProvider className="no-scrollbar">
      <div style={{ marginBottom: '1em' }}>
        <Media greaterThan='mobile'>
          {/* Use Grid for Desktop layout */}
          <Grid>
            <Grid.Row columns={2} >
              <Grid.Column textAlign='right'>
              </Grid.Column>
              <Grid.Column textAlign='right'>
                <Dropdown
                  placeholder='Select Team'
                  selection
                  options={teams}
                  value={selectedTeam}
                  onChange={(e, data) => setSelectedTeam(data.value)}
                  style={{marginRight: '1em'}}
                />
                <Dropdown
                  placeholder='Select Individual'
                  selection
                  options={individuals}
                  value={selectedIndividual}
                  onChange={(e, data) => setSelectedIndividual(data.value)}
                  style={{marginRight: '1em'}}
                />
              </Grid.Column >
            </Grid.Row>
          </Grid>
        </Media>
      </div>
      <Media at='mobile'>
        <NoScrollbarForBody />
        <Media at='mobile'>
          {isDropdownVisible && (
            <div
              className={`dropdown-transition ${!isDropdownVisible ? 'hidden' : ''}`}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
            >

              <Dropdown
                placeholder='Select Team'
                selection
                options={teams}
                value={selectedTeam}
                onChange={(e, data) => setSelectedTeam(data.value)}
                style={{ width: '90%', margin: '0.5em 0' }}
              />
              <Dropdown
                placeholder='Select Individual'
                selection
                options={individuals}
                value={selectedIndividual}
                onChange={(e, data) => setSelectedIndividual(data.value)}
                style={{ width: '90%', margin: '0.5em 0' }}
              />
            </div>
          )}
        </Media>
        <MobileKanbanBoard columnsData={columnsData} moveCard={moveCard} scrollingContainerRef={scrollingContainerRef} isDropdownVisible={isDropdownVisible} />
      </Media>
      <Media greaterThan='mobile'>
        <DesktopKanbanBoard columnsData={columnsData} moveCard={moveCard} scrollingContainerRef={scrollingContainerRef} />
      </Media>
    </MediaContextProvider>
  );
};



const Kanban = () => (
  <DndProvider backend={HTML5Backend}>
    <AppHeader>
      <div style={styles.feedWrapper} className="no-scrollbar">
        <KanbanBoard />
      </div>
      <AppFooter />
    </AppHeader>
  </DndProvider>
);


export default Kanban;
