import { authenticatedFetch } from './api';

export const fetchAlerts = async () => {
  try {
    const response = await authenticatedFetch('/api/alerts');
    return response;
  } catch (error) {
    throw new Error('There was an error fetching the alerts: ' + error.message);
  }
};

export const fetchUnreadAlertsCount = async () => {
  try {
    const response = await authenticatedFetch('/api/alerts/unread-count');
    return response.count;
  } catch (error) {
    throw new Error('There was an error fetching the unread alerts count: ' + error.message);
  }
};

export const markAlertAsRead = async (alertId) => {
    try {
      await authenticatedFetch(`/api/alerts/${alertId}/read`, {
        method: 'PATCH',
      });
    } catch (error) {
      throw new Error('There was an error marking the alert as read: ' + error.message);
    }
  };