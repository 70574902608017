import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { fetchUserTeams } from '../api/apiTeam';
import { fetchGoalsForTeam, createGoal } from '../api/apiGoal';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonLabel,
  IonInput,
  IonTextarea,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonCheckbox,
  IonAlert,
  IonLoading,
} from '@ionic/react';

// Predefined options for dropdowns
const goalTypeOptions = [
  { key: 'Team', text: 'Team', value: 'Team' },
  { key: 'Individual', text: 'Individual', value: 'Individual' },
];

const frequencyOptions = [
  { key: 'daily', value: 'Daily', text: 'Daily' },
  { key: 'weekly', value: 'Weekly', text: 'Weekly' },
  { key: 'monthly', value: 'Monthly', text: 'Monthly' },
  { key: 'quarterly', value: 'Quarterly', text: 'Quarterly' },
];

export default function CreateGoal({ mobile }) {
  const [parentGoals, setParentGoals] = useState([]);
  const [showParentGoalDropdown, setShowParentGoalDropdown] = useState(false);
  const [teams, setTeams] = useState([]);
  const [isRecurring, setIsRecurring] = useState(false);
  const [goalFormData, setGoalFormData] = useState({
    type: '',
    associatedTeam: '',
    title: '',
    parentGoal: '',
    targetCompletionDate: '',
    goalType: '',
    recurringFrequency: '',
    description: ''
  });
  const [loading, setLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const activeOrgId = localStorage.getItem('activeOrganization');

    fetchUserTeams(userId, activeOrgId)
      .then(data => {
        setTeams(data);
      })
      .catch(error => {
        console.error("Error fetching teams:", error);
        setFeedbackMessage({
          type: "error",
          content: "Error fetching teams: " + (error.message || error)
        });
      });
  }, []);

  const handleInputChange = (name, value) => {
    setGoalFormData(prev => ({ ...prev, [name]: value }));
    if (name === 'associatedTeam') {
      fetchGoalsForTeam(value)
        .then(data => {
          if (data.length > 0) {
            const parentGoalOptions = data.map(goal => ({
              key: goal._id,
              text: goal.title,
              value: goal._id,
            }));
            setParentGoals(parentGoalOptions);
            setShowParentGoalDropdown(true);
          } else {
            setShowParentGoalDropdown(false);
          }
        })
        .catch(error => console.error("Error fetching goals:", error));
    }
  };

  const validateForm = () => {
    const { type, title, targetCompletionDate, goalType, recurringFrequency } = goalFormData;

    if (!type || !title || !targetCompletionDate || !goalType) {
      return "All fields are required!";
    }

    if (isRecurring && !recurringFrequency) {
      return "Recurring frequency is required!";
    }

    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationError = validateForm();
    if (validationError) {
      setFeedbackMessage({
        type: "error",
        content: validationError
      });
      return;
    }

    setLoading(true);

    try {
      const requestData = {
        ...goalFormData,
        organizationId: localStorage.getItem('activeOrganization'),
        userId: localStorage.getItem('userId')
      };

      if (requestData.parentGoal === '') {
        requestData.parentGoal = null;
      }

      if (!isRecurring) {
        delete requestData.recurringFrequency;
      }

      await createGoal(requestData);

      setFeedbackMessage({
        type: "success",
        content: "Goal created successfully!"
      });

      setGoalFormData({
        type: '',
        associatedTeam: '',
        title: '',
        parentGoal: '',
        targetCompletionDate: '',
        goalType: '',
        recurringFrequency: '',
        description: ''
      });
      setIsRecurring(false);
      history.push('/success'); // Use history.push instead of navigate
    } catch (error) {
      setFeedbackMessage({
        type: "error",
        content: error.message || 'An error occurred while creating the goal.'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    history.push('/next-page'); // Update with the actual next page route
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Create A Goal</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        fullscreen
        style={{
          '--background': 'linear-gradient(-45deg, #006d77, #83c5be)',
        }}
      >
        <div
          style={{
            width: "100%",
            minHeight: "100vh",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: mobile ? '1em' : '2em',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            boxSizing: 'border-box',
          }}
        >
          <div
            style={{
              maxWidth: '500px',
              width: '100%',
              borderRadius: '8px',
              marginBottom: '2em',
            }}
          >
          <IonList style={{ borderRadius: '10px', overflow: 'hidden' }}>
            <IonItem>
              <IonSelect
                value={goalFormData.type}
                onIonChange={(e) => handleInputChange('type', e.detail.value)}
                labelPlacement="floating"
                label="Type"
                placeholder="Individual or Team"
              >
                {goalTypeOptions.map(option => (
                  <IonSelectOption key={`goalType-${option.key}`} value={option.value}>{option.text}</IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonSelect
                value={goalFormData.associatedTeam}
                onIonChange={(e) => handleInputChange('associatedTeam', e.detail.value)}
                labelPlacement="floating"
                label="Select Team"
                placeholder="Select Team"
                interface="alert" // You can also try "popover"
              >
                {teams.map(team => (
                  <IonSelectOption key={`team-${team.key}`} value={team.value}>{team.text}</IonSelectOption>
                ))}
              </IonSelect>    
            </IonItem>
            <IonItem>
              <IonInput
                name="title"
                placeholder="Goal Title"
                labelPlacement="floating"
                label="Goal Title"
                maxLength="100"
                value={goalFormData.title}
                onIonChange={(e) => handleInputChange('title', e.detail.value)}
              />
            </IonItem>
            {showParentGoalDropdown && (
              <IonItem>
                <IonSelect
                  value={goalFormData.parentGoal}
                  onIonChange={(e) => handleInputChange('parentGoal', e.detail.value)}
                  labelPlacement="floating"
                  label="Parent Goal"
                  placeholder="Select Parent Goal"
                >
                  {parentGoals.map(goal => (
                    <IonSelectOption key={`parentGoal-${goal.key}`} value={goal.value}>{goal.text}</IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            )}
            <IonItem>
              <IonInput
                type="date"
                name="targetCompletionDate"
                placeholder="Target Date"
                labelPlacement="floating"
                label="Target Date"
                value={goalFormData.targetCompletionDate}
                onIonChange={(e) => handleInputChange('targetCompletionDate', e.detail.value)}
              />
            </IonItem>
            <IonItem>
              <IonSelect
                value={goalFormData.goalType}
                onIonChange={(e) => handleInputChange('goalType', e.detail.value)}
                labelPlacement="floating"
                label="Goal Type"
                placeholder="Select Type"
              >
                {frequencyOptions.map(option => (
                  <IonSelectOption key={`goalTypeFreq-${option.key}`} value={option.value}>{option.text}</IonSelectOption>
                ))} 
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonCheckbox
                slot="start"
                checked={isRecurring}
                onIonChange={(e) => {
                  setIsRecurring(e.detail.checked);
                  if (!e.detail.checked) {
                    setGoalFormData(prev => ({ ...prev, recurringFrequency: '' }));
                  }
                }}
              />
              <IonLabel>Is it recurring?</IonLabel>
            </IonItem>
            {isRecurring && (
              <IonItem>
                <IonSelect
                  value={goalFormData.recurringFrequency}
                  onIonChange={(e) => handleInputChange('recurringFrequency', e.detail.value)}
                  labelPlacement="floating"
                  label="Recurring Frequency"
                  placeholder="Select Recurring Frequency"
                >
                  {frequencyOptions.map(option => (
                    <IonSelectOption key={`recurringFreq-${option.key}`} value={option.value}>{option.text}</IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            )}
            <IonItem>
              <IonTextarea
                name="description"
                placeholder="Long Description"
                labelPlacement="floating"
                label="Long Description"
                value={goalFormData.description}
                onIonChange={(e) => handleInputChange('description', e.detail.value)}
              />
            </IonItem>
          </IonList>

          <div style={{ marginTop: '1em', marginBottom: '1em' }}>
            {feedbackMessage && (
              <IonAlert
                isOpen={!!feedbackMessage}
                onDidDismiss={() => setFeedbackMessage(null)}
                message={feedbackMessage.content}
                buttons={['OK']}
              />
            )}
            <IonButton expand="block" onClick={handleSubmit}>
              Submit
            </IonButton>
          </div>
          </div>
          {/* You can include additional components here if needed */}
        </div>
        <IonLoading
          isOpen={loading}
          message={'Creating goal...'}
        />
      </IonContent>
    </IonPage>
  );
}
